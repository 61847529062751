import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const SAVE_CONSENT = gql`
  mutation addConsentRecord(
    $userEmail: String
    $userSub: String
    $surveyId: ID
    $consentGiven: ConsentGivenInput
    $method: String
  ) {
    addConsentRecord(
      userEmail: $userEmail
      userSub: $userSub
      surveyId: $surveyId
      consentGiven: $consentGiven
      method: $method
    )
  }
`;

const useConsent = () => {
  const [addConsentRecord] = withSessionHandler(useMutation(SAVE_CONSENT));

  return {
    addConsentRecord,
  };
};

export default useConsent;
