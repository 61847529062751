import {
  ADD_ADDITIONAL_STAKEHOLDER,
  REMOVE_ADDITIONAL_STAKEHOLDER,
} from '../actions/actionTypes';

const additional = (state = [], action) => {
  switch (action.type) {
    case ADD_ADDITIONAL_STAKEHOLDER:
      return [action.name, ...state];
    case REMOVE_ADDITIONAL_STAKEHOLDER:
      return state.filter((stakeholder) => stakeholder !== action.name);
    default:
      return state;
  }
};

export default additional;
