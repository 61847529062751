import { Container, Row, Col } from 'reactstrap';
import React from 'react';
import Hero from 'components/landings/Hero';
import trainerPresentingEaselIcon from 'public/assets/trainer-presenting-easel.svg';
import adeptionExperienceScreensGraphic from 'public/assets/adeption-experience-screens.png';
import adeptionDesktop from 'public/assets/adeption-desktop.jpg';
import collaborationIcon from 'public/assets/collaboration-icon.svg';
import influenceIcon from 'public/assets/influence-icon.svg';
import innovationIcon from 'public/assets/innovation-icon.svg';
import LandingButton from 'components/LandingButton';
import {
  lndCertifiedTrainerCourseLink,
} from 'lib/constants';

const section1 = (
  <Row>
    <Col>
      <p>
        Network Leader is proud to partner with leadership development platform Adeption
        to offer a new Certified Trainer experience.
      </p>
    </Col>
  </Row>
);

const section2 = (
  <Row className="orange-border p-4">
    <Col className="col-12 col-sm-8 pb-4">
      <Row>
        <Col>
          <p>
            Join us in making the complex world of network research and
            analysis easy for anyone to understand and apply.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center justify-content-sm-start">
          <LandingButton color="lndOrange" url={lndCertifiedTrainerCourseLink} text="Access the online course here" newTab />
        </Col>
      </Row>
    </Col>
    <Col className="col-12 col-sm-4">
      <img src={trainerPresentingEaselIcon.src} alt="" />
    </Col>
  </Row>
);

const section3 = (
  <Row>
    <Col>
      <h3 className="pb-2" style={{ textAlign: 'left' }}>
        Certified Trainers get access to:
      </h3>
      <ul>
        <li>
          A custom training experience created by leadership development experts and organizational
          psychologists
        </li>
        <li>
          A full suite of training materials including a detailed delivery framework, presentation,
          FAQ with citations/studies, prework and video walk through
        </li>
        <li>The Diagnostic itself to map their own complex, professional networks</li>
        <li>A Diagnostic self-guided experience for additional learning and context</li>
        <li>The reporting and participant management portal</li>
      </ul>
    </Col>
  </Row>
);

const section4 = (
  <Row>
    <Col className="d-flex justify-content-center">
      <img
        src={adeptionExperienceScreensGraphic.src}
        style={{ width: '100%', maxWidth: '800px' }}
        alt=""
      />
    </Col>
  </Row>
);

const section5 = (
  <Row>
    <Col>
      <h3 className="pb-4">
        The Leader Network Diagnostic is a flexible, fully digital
        assessment that can be delivered and debriefed in a
        variety of different ways
      </h3>
      <p className="text-center">
        Human Resources professionals, leadership trainers, executive coaches,
        university professors, network scholars, and more have used our training
        and development program to help individuals build networks for future success.
      </p>
      <p className="text-center">
        We’ve designed the Leader Network Diagnostic to facilitate much of the technical learning,
        allowing you to focus on facilitating conversations among participants that
        foster their growth and development.
      </p>
    </Col>
  </Row>
);

const section6 = (
  <Row className="blue-border">
    <Col>
      <Row className="p-4">
        <Col>
          <h3>As a Certified Trainer, you will help your organization improve:</h3>
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-4 p-4">
          <div className="d-flex justify-content-center p-2">
            <img src={collaborationIcon.src} style={{ height: '120px' }} alt="" />
          </div>
          <h3>Collaboration</h3>
          <p className="text-center">Break down silos</p>
        </Col>
        <Col className="col-sm-4 p-4">
          <div className="d-flex justify-content-center p-2">
            <img src={innovationIcon.src} style={{ height: '120px' }} alt="" />
          </div>
          <h3>Innovation</h3>
          <p className="text-center">Solving complex problems</p>
        </Col>
        <Col className="col-sm-4 p-4">
          <div className="d-flex justify-content-center p-2">
            <img src={influenceIcon.src} style={{ height: '120px' }} alt="" />
          </div>
          <h3>Influence</h3>
          <p className="text-center">Creating alignment</p>
        </Col>
      </Row>
    </Col>
  </Row>
);

const section7 = (
  <Row>
    <Col className="d-flex justify-content-center">
      <img
        src={adeptionDesktop.src}
        style={{ width: '100%', maxWidth: '800px' }}
        alt=""
      />
    </Col>
  </Row>
);

const section8 = (
  <Row className="orange-border">
    <Col className="p-4">
      <h3 style={{ textAlign: 'left' }}>Outcomes</h3>
      <p>
        As a result of taking The Diagnostic, professionals will be better able to:
      </p>
      <ul>
        <li>Expand the flow of information and opportunities in their network</li>
        <li>
          Grow relationships that enable them to more easily navigatecomplex
          or flattened hierarchies
        </li>
        <li>
          Identify and develop relationships that cross critical
          boundaries or provide key resources that are missing from their network
        </li>
        <li>Strengthen relationships that will increase the success of their professional goals</li>
        <li>
          Add value to others by connecting people in
          their network who would benefit most from knowing each other
        </li>
      </ul>
    </Col>
  </Row>
);

const section9 = (
  <Row>
    <Col className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
      <h4>Already a trainer?</h4>
    </Col>
    <Col className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
      <LandingButton color="lndOrange" url="/trainer" text="Go to the Trainer Page" newTab={false} />
    </Col>
  </Row>
);

export default function TrainerCoursePageLayout() {
  return (
    <Container fluid className="landing-container">
      <Hero intro="Become a" title="CERTIFIED TRAINER" color="#F59120" />
      <div className="max_desktop_width_70">
        <Row className="px-4 mb-4">
          <Col>
            {section1}
          </Col>
        </Row>
        <Row className="px-4 mb-4">
          <Col>
            {section2}
          </Col>
        </Row>
        <Row className="px-4 mb-4">
          <Col>
            {section3}
          </Col>
        </Row>
        <Row className="px-4 mb-4 background-grey">
          <Col>
            {section4}
          </Col>
        </Row>
        <Row className="px-4 mb-4">
          <Col>
            {section5}
          </Col>
        </Row>
        <Row className="px-4 mb-4">
          <Col>
            {section6}
          </Col>
        </Row>
        <Row className="px-4 mb-4">
          <Col>
            {section7}
          </Col>
        </Row>
        <Row className="px-4 mb-4">
          <Col>
            {section8}
          </Col>
        </Row>
        <Row className="px-4">
          <Col>
            {section9}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
