import { SAVE_CONSENT } from '../actions/actionTypes';

const defaultState = {};

const consent = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_CONSENT:
      return {
        ...state,
        ...action.consent,
      };
    default:
      return state;
  }
};

export default consent;
