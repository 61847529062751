import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Hero } from 'components/landings';
import Phil from 'public/assets/Philip-Willburn.jpg';
import Ryan from 'public/assets/Ryan-Higley.jpg';
import Kristin from 'public/assets/Kristen-Cullen-Lester.jpg';
import Chris from 'public/assets/chris-kirkos-m.jpg';
import Cassaundra from 'public/assets/cass-bioimage1.jpg';

function Bio({ bioImgSrc, titleText, aboutText, left = false }) {
  const directionClass = left
    ? 'order-sm-first justify-content-sm-end'
    : 'order-sm-last justify-content-sm-start';
  return (
    <Row className="justify-content-xs-center">
      <Col className={`${directionClass} col-sm-4 d-flex justify-content-center py-2`}>
        <img className=" about_image" src={bioImgSrc} />
      </Col>
      <Col className="col-sm-8 pt-4 d-flex align-items-center">
        <div>
          <p>
            <strong>{titleText}</strong>
          </p>
          <p className="about_text">{aboutText}</p>
        </div>
      </Col>
    </Row>
  );
}

// const t = (strings) => { return strings.map((s) => s.replace(/\s+/g, ' ')).join("").trim() }

const philAboutText = (
  <>
    Phil is currently the Vice President of People Analytics at Workday where he helps
    Workday make better people decisions by creating data-driven solutions for HR and
    Business leaders. Prior to joining Workday, he was a senior faculty member at the
    Center for Creative Leadership where he integrated a &quot;network perspective&quot;
    into leadership development strategies and programs for global Fortune 500 companies.
    Early in his career Phil worked inside the intelligence community teaching network
    identification and analysis. It was this experience that inspired him to bring the
    complex world of network research and analysis to everyone by making it easy to
    understand and apply.
  </>
);

const kristinAboutText = (
  <>
    Kristin is an organizational psychologist and Assistant Professor of Management at the
    University of Mississippi. She received her Ph.D. from Auburn University in 2011. Her
    research focuses on relational aspects of leadership, including the role of networks
    in leadership effectiveness, complex collaboration, and implementing change within
    organizations. She publishes her research in top academic journals. For example, her
    paper,{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.researchgate.net/publication/309275739_Incorporating_social_networks_into_leadership_development_A_conceptual_model_and_evaluation_of_research_and_practice"
    >
      Integrating networks into leadership development
    </a>
    : A conceptual model and evaluation of research and practice, was recognized as the
    best paper published in 2017 in The Leadership Quarterly.{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.kristincullen-lester.com/"
    >
      Learn more
    </a>{' '}
    about Kristin&apos;s ongoing research, including a project examining the networks of
    top executives.
  </>
);

const chrisAboutText = (
  <>
    Chris is an experienced Software Engineering Lead with an academic background in
    Social Network Analysis. During the day, he builds scalable software systems and
    technology teams. At night, he researches networks and builds tools to help people
    understand and improve theirs. He studied Computer Science and Software Engineering at
    Steven's Institute of Technology, and Social Network Analysis at George Mason
    University.
  </>
);

const ryanAboutText = (
  <>
    Ryan is a multi-media designer who knows how important effective design can be in
    helping people understand complex ideas. His BFA in animation and his Masters in
    graphic information technology helps him craft videos, layouts, and user experiences
    for Network Leader that communicate those important ideas in clear and concise ways.
    Working as an instructional designer, video editor and graphic designer in the
    education and leadership development field for a majority of his career informs a lot
    of his work with Network Leader. Ryan also created and manages his personal YouTube
    channel with 40 million views and 20 thousand subscribers which helps him understand
    the community building and analytics side of online marketing.
  </>
);

const cassAboutText = (
  <>
    Cassaundra Brownell is an experienced executive and serial entrepreneur. She is
    currently CFO and founder/co-owner to a real estate company, an operations management
    company, a logistics company, and two transportation companies. She believes that the
    bottom line is reflective of strong teams and processes. She has a strong background
    in strategy and consulting and has partnered with Fortune 100 organizations in
    creating solutions and learning journeys that enable their people strategies and drive
    business outcomes. Prior to joining Network Leader, she was a Strategic Business
    Partner at the Center for Creative Leadership where she oversaw the delivery of
    leadership development, assessments, global learning products (digital), executive
    coaching products, and services. Cassaundra holds a BS in Marketing and an MBA in
    Entrepreneurship.
  </>
);

const bioData = {
  phil: {
    bioImgSrc: Phil.src,
    titleText: 'Phil Willburn - Strategic Advisor, Board of Directors',
    aboutText: philAboutText,
  },
  kristin: {
    bioImgSrc: Kristin.src,
    titleText: 'Kristin Cullen-Lester, PhD - Chief People Scientist',
    aboutText: kristinAboutText,
  },
  chris: {
    bioImgSrc: Chris.src,
    titleText: 'Christopher Kirkos - Technology',
    aboutText: chrisAboutText,
  },
  ryan: {
    bioImgSrc: Ryan.src,
    titleText: 'Ryan Higley - Creative Director',
    aboutText: ryanAboutText,
  },
  cass: {
    bioImgSrc: Cassaundra.src,
    titleText: 'Cassaundra Brownell - Chief Revenue Officer',
    aboutText: cassAboutText,
  },
};

export default function AboutPageLayout() {
  return (
    <Container fluid>
      <Hero intro="See who's on" title="OUR TEAM" color="#BC202E" />
      <div className="max_desktop_width_70">
        <Bio {...bioData.kristin} />
        <hr />
        <Bio {...bioData.cass} left />
        <hr />
        <Bio {...bioData.ryan} />
        <hr />
        <Bio {...bioData.chris} left />
        <hr />
        <Bio {...bioData.phil} />
      </div>
    </Container>
  );
}
