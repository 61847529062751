import { ADD_COHORT_CODE } from '../actions/actionTypes';

const cohortCode = (state = '', action) => {
  switch (action.type) {
    case ADD_COHORT_CODE:
      return action.payload;
    default:
      return state;
  }
};

export default cohortCode;
