import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Hero, ModalVideo, ContentIcon } from 'components/landings';
import CertifiedIcon from 'public/assets/Home-Certified_Trainer.svg';
import NetworkIcon from 'public/assets/Home-Improve_Network.svg';
import ScienceIcon from 'public/assets/Home-Network_Science.svg';
import DataIcon from 'public/assets/Home-Data_Driven_Analysis.svg';
import Preview from 'public/assets/impact-video-thumbnail.jpg';
import {
  contactUsIndividualLink,
  lndBlue,
  lndOrange,
  lndGreen,
} from 'lib/constants';
import * as Sentry from '@sentry/nextjs';
import LandingButton from '../LandingButton';

export default function HomePageLayout() {
  return (
    <Container fluid className="landing-container">
      <Hero
        intro="Grow Data-Driven"
        title="PROFESSIONAL NETWORKS"
        color="#0e75bb"
      />
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-5">
        <Col className="col-12 col-lg-6">
          <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
            Leverage the full potential of your professional connections with
            Network Leader’s data-driven tools, powerful network solutions, and
            extensive research.
          </p>
        </Col>
        <Col className="landing_display d-flex justify-content-center col-12 col-lg-5">
          <ModalVideo
            previewImage={Preview}
            videoUrl="https://vimeo.com/networkleader/lndstories"
          />
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-5">
        <Col className="d-flex justify-content-center col-lg-11 pb-4">
          <p className="text-center landing_paragraph_normal">
            We work with businesses, universities, governments and non-profits
            to offer flexible packages of professional network assessments.
            Contact us to see how our custom network solutions and comprehensive
            training can help your people.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12">
          <LandingButton
            color="orange"
            url={contactUsIndividualLink}
            text="Contact Us"
            newTab
          />
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-5">
        <Col className="d-flex justify-content-center col-12 col-lg-11 pb-4">
          <p className="text-center landing_paragraph_normal">
            Developing your professional network is the single most important
            driver of a successful career. Unfortunately, most people either
            don’t understand the potential of their own professional network or
            confuse it with their social network. We’re here to help our members
            understand, measure, and leverage the power of their professional
            connections.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12 col-lg-11">
          <p className="text-center landing_paragraph_normal">
            The Leader Network Diagnostic is a 20-minute individual assessment
            that will help you visualize and understand your professional
            network. The digital assessment can be taken from any device and
            includes comprehensive videos that guide you through the experience,
            explaining questions and concepts. Additionally, you will receive a
            custom report to help you make sense of your network data and create
            an action plan for your future. This multi-media report has
            educational videos that give context to the data and results. Each
            report has simple steps you can take to improve your professional
            network. These steps are personalized to you, and are based on your
            specific network data.
          </p>
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-5">
        <Col className="col-12 col-lg-4 pb-5">
          <ContentIcon
            icon={ScienceIcon}
            learnMore
            learnMoreUrl="/research"
            color="blue"
            text="Learn about new network science on professional networks"
          />
        </Col>
        <Col className="col-12 col-lg-4 pb-5">
          <ContentIcon
            icon={NetworkIcon}
            learnMore
            learnMoreUrl="/diagnostic"
            color="orange"
            text="Assess and improve your network with simple proven steps."
          />
        </Col>
        <Col className="col-12 col-lg-4 pb-3">
          <ContentIcon
            icon={CertifiedIcon}
            learnMore
            learnMoreUrl="/certified-trainers"
            color="green"
            text="Become a certified trainer and teach others"
          />
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-5">
        <Col className="col-12 col-lg-4 d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0 py-5 px-3 highlight_image_col">
          <img
            className="pb-3"
            src={DataIcon.src}
            style={{ maxWidth: '150px' }}
          />
          <p className="landing_paragraph_normal">Data-Driven</p>
          <p className="landing_paragraph_normal">Professional Networks</p>
        </Col>
        <Col className="col-12 col-lg-8 d-flex flex-column align-items-start justify-content-center pt-2">
          <p className="ps-md-4 landing_paragraph_normal ms-lg-5 pb-2">
            Network Leader leverages over 40 years of scientific research to
            create tools that effectively analyze key aspects of professional
            networks. These assessments provide data-driven answers to critical
            questions including:
          </p>
          <ul className="highlight_list landing_paragraph_normal ms-lg-5">
            <li className="landing_paragraph_normal ps-2 ps-sm-5 pb-4">
              What makes an effective network?
            </li>
            <li className="landing_paragraph_normal ps-2 ps-sm-5 pb-4">
              What network patterns derail leaders?
            </li>
            <li className="landing_paragraph_normal ps-2 ps-sm-5">
              What proven methods can I use to strengthen my network?
            </li>
          </ul>
        </Col>
      </Row>
      {' '}
    </Container>
  );
}
