import { useEffect } from 'react';
import { addCohortCode } from 'lib/redux/actions';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'next/navigation';

const useCohortCode = (save = true) => {
  const params = useParams();
  const searchParams = useSearchParams();
  let { code } = params;

  if (!code) {
    code = searchParams.get('code');
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (code && save) {
      dispatch(addCohortCode(code));
    }
  }, [code]);

  return code;
};

const useCohortCodeUrl = (baseUrl) => {
  const code = useCohortCode();
  return code ? `${baseUrl}?code=${code}` : baseUrl;
};

export { useCohortCode, useCohortCodeUrl };
