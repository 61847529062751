import React from 'react';
import { Col } from 'reactstrap';
import { ModalVideo } from 'components/landings';

import NLDiagnostic_impact_1 from 'public/NLDiagnostic_impact_1.jpg';
import NLDiagnostic_impact_2 from 'public/NLDiagnostic_impact_2.jpg';
import NLDiagnostic_impact_3 from 'public/NLDiagnostic_impact_3.jpg';
import NLDiagnostic_impact_4 from 'public/NLDiagnostic_impact_4.jpg';

const testimonialsContent = [
  {
    previewImage: NLDiagnostic_impact_1,
    text: 'Networks',
    url: 'https://vimeo.com/422585666',
  },
  {
    previewImage: NLDiagnostic_impact_2,
    text: 'Impact',
    url: 'https://vimeo.com/430572389',
  },
  {
    previewImage: NLDiagnostic_impact_3,
    text: 'Insight',
    url: 'https://vimeo.com/430572488',
  },
  {
    previewImage: NLDiagnostic_impact_4,
    text: 'Power',
    url: 'https://vimeo.com/430572540',
  },
];

function TestimonialVideosLayout() {
  return (
    <>
      {testimonialsContent.map(({ previewImage, url, text }) => (
        <Col key={url} className="testimonial_col col-12 col-lg-6">
          <ModalVideo previewImage={previewImage} videoUrl={url} />
          <div className="testimonial_video_title_container">
            <p className="testimonial_video_title">{text}</p>
          </div>
        </Col>
      ))}
    </>
  );
}

export default TestimonialVideosLayout;
