module.exports = {
  surveysCollection: 'surveys',
  goalsCollection: 'goals',
  consentCollection: 'consent',
  retakeAnswersCollection: 'retakeAnswers',
  cohortsCollection: 'cohorts',
  organizationsCollection: 'organizations',
  contactsCollection: 'contacts',
  resultsCollection: 'results',
  emailsCollection: 'emails',
  deafultPurchaseAmount: 5500,
  contactUsTrainerLink:
    'https://forms.monday.com/forms/e9847777907f164c0cbdf575f43fa7bb?r=use1',
  contactUsIndividualLink:
    'https://forms.monday.com/forms/590417146380b957bf0dd4179de7fb8f?r=use1',
  lndIndividualCourseLink: 'https://buy.stripe.com/6oE2aDgxkbojapW5km',
  lndCertifiedTrainerCourseLink: 'https://buy.stripe.com/5kAaH94OCdwr7dK5kt',
  lndOrange: '#f59120',
  lndBlue: '#0e75bb',
  lndGreen: '#2bb573',
  lndPurple: '#652f8e',
  lndOrangeRGBOpaque: 'rgb(245, 145, 32, .5)',
  lndBlueRGBOpaque: 'rgb(27, 117, 186, .5)',
  lndGreenRGBOpaque: 'rgb(39, 179, 115, .5)',
  lndPurpleRGBOpaque: 'rgb(101, 47, 142, .5)',
  lndOrangeRGB: 'rgb(245, 145, 32)',
  lndBlueRGB: 'rgb(27, 117, 186)',
  lndGreenRGB: 'rgb(39, 179, 115)',
  lndPurpleRGB: 'rgb(101, 47, 142)',
  DAYS_WINDOW: Number(process.env.DAYS_WINDOW) || 30,

  MUCH_OLDER: 'much-older',
  OLDER: 'older',
  YOUNGER: 'younger',
  MUCH_YOUNGER: 'much-younger',
  SAME: 'same',
  NONE: 'none',
  SIMILAR: 'similar',
  DIFFERENT: 'different',
  UNKNOWN: 'unknwon',
  NA: 'na',

  // Survey's version
  DEFAULT: 'default',
  APRIL_2023: 'APRIL_2023',
  APRIL_2024: 'APRIL_2024',

  aboutYouOptions: {
    age: [
      { display: '25 and under', value: '25' },
      { display: '26 - 35', value: '35' },
      { display: '36 - 45', value: '45' },
      { display: '46 - 55', value: '55' },
      { display: '56 - 65', value: '65' },
      { display: 'None', value: 'none' },
    ],
    gender: [
      { display: 'Male', value: 'male' },
      { display: 'Female', value: 'female' },
      { display: 'Other', value: 'other' },
      { display: 'None', value: 'none' },
    ],
    leader: [
      { display: 'Full-Time Student', value: 'student' },
      { display: 'Seeking Employment', value: 'seeking' },
      {
        display: 'Individual Contributor or Professional',
        value: 'individual',
      },
      { display: 'First Level Supervisor', value: 'first' },
      { display: 'Mid Level Manager', value: 'middle' },
      { display: 'Senior Level Leader', value: 'senior' },
      { display: 'C-Suite Executive', value: 'chief' },
      { display: 'Other', value: 'other' },
    ],
    education: [
      { display: 'High School Diploma', value: 'highSchool' },
      { display: 'Associate’s Degree', value: 'associate' },
      { display: 'Bachelor’s Degree', value: 'bachelor' },
      { display: 'Master’s Degree', value: 'master' },
      {
        display: 'Ph.D. or Professional Degree (M.D., J.D., D.D.S.)',
        value: 'phd',
      },
      { display: 'I prefer not to say', value: 'optOut' },
    ],
    industry: [
      { display: 'Armed Forces', value: 'armed' },
      { display: 'Consumer Discretionary', value: 'discretionary' },
      { display: 'Consumer Sales', value: 'sales' },
      { display: 'Education', value: 'education' },
      { display: 'Energy', value: 'energy' },
      { display: 'Financials', value: 'financials' },
      { display: 'Health Care', value: 'health' },
      { display: 'Industrials', value: 'industrials' },
      { display: 'Information Technology', value: 'it' },
      { display: 'Materials', value: 'materials' },
      { display: 'Real Estate', value: 'real-estate' },
      { display: 'Telecommunication Services', value: 'telecom' },
      { display: 'Utilities', value: 'utilities' },
      { display: 'Other', value: 'other' },
    ],
    department: [
      { display: 'Administration / Support', value: 'admin' },
      {
        display: 'Customer Services / Customer Care',
        value: 'customer-service',
      },
      {
        display: 'Consulting / Project Management',
        value: 'consulting',
      },
      {
        display: 'Facilities / Maintenance / Security',
        value: 'facilities',
      },
      { display: 'Finance and Accounting', value: 'finance' },
      { display: 'Human Resources', value: 'hr' },
      { display: 'Information Technology', value: 'it' },
      {
        display: 'Manufacturing / Production / Quality',
        value: 'manufacturing',
      },
      { display: 'Marketing / Advertising', value: 'marketing' },
      { display: 'Medical / Other Healthcare', value: 'medical' },
      { display: 'Nursing / Clinical', value: 'nursing' },
      {
        display: 'Operations and Quality Assurance',
        value: 'operations',
      },
      { display: 'Research and Development', value: 'research' },
      { display: 'Sales / Business Development', value: 'sales' },
      { display: 'Other', value: 'other' },
    ],
    ethnicity: [
      { display: 'None/Outside US', value: 'outside' },
      { display: 'White', value: 'white' },
      {
        display: 'Black or African American',
        value: 'black-or-african-american',
      },
      { display: 'Hispanic or Latino', value: 'hispanic-or-latino' },
      {
        display: 'American Indian or Alaska Native',
        value: 'american-indian-or-alaska-native',
      },
      { display: 'Asian', value: 'asian' },
      {
        display: 'Native Hawaiian or Other Pacific Islander',
        value: 'native-hawaiian-or-other-pacific-islander',
      },
      { display: 'Other', value: 'other' },
      { display: 'Decline to Answer', value: 'decline-to-answer' },
    ],
  },
};
