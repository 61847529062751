import { v4 } from 'uuid';
import {
  ADD_PERSON,
  REMOVE_PERSON,
  UPDATE_PERSON,
  UPDATE_ALL_PERSON,
  ADD_CONNECTION,
  REMOVE_CONNECTION,
  ADD_STAKEHOLDER,
  REMOVE_STAKEHOLDER,
  ADD_ADDITIONAL_STAKEHOLDER,
  REMOVE_ADDITIONAL_STAKEHOLDER,
  UPDATE_RESULTS,
  ADD_COHORT_CODE,
  SET_ACTIVE_SURVEY_ID,
  ADD_SURVEYS_FOR_TRAINER,
  ADD_COHORT_STATISTICS,
  LIST_COHORTS,
  SELECT_COHORT,
  ADD_COHORT_TO_STORE,
  UPDATE_COHORT_IN_STORE,
  REMOVE_COHORT_FROM_STORE,
  SET_SURVEY_COHORT,
  REMOVE_SURVEY_COHORT,
  SET_USER,
  SET_IS_SAVING_SURVEY,
  SET_COHORT_COUNT,
  SET_COHORTS_CURRENT_PAGE,
  SET_COHORTS_ORDER,
  SET_COHORTS_SEARCH_TERM,
  SET_IS_COHORTS_MODAL_OPEN,
  SET_COHORTS_ERROR,
  LIST_ORGANIZATIONS,
  SET_ORGANIZATIONS_COUNT,
  SET_ORGANIZATIONS_ERROR,
  SELECT_ORGANIZATION,
  SET_ORGANIZATIONS_CURRENT_PAGE,
  SET_ORGANIZATIONS_ORDER,
  SET_ORGANIZATIONS_SEARCH_TERM,
  SET_IS_ORGANIZATIONS_MODAL_OPEN,
  ADD_ORGANIZATION_TO_STORE,
  UPDATE_ORGANIZATION_IN_STORE,
  REMOVE_ORGANIZATION_FROM_STORE,
  SET_IS_CONTACTS_MODAL_OPEN,
  LIST_CONTACTS,
  SET_CONTACTS_ERROR,
  SET_CONTACTS_IS_FETCHING,
  SET_CONTACTS_IS_OUTDATED,
  SAVE_GOALS,
  RESET_GOALS,
  SET_SURVEY_COUNT,
  SAVE_PREVIOUS_SURVEY_ID,
  SAVE_USER_GOALS,
  SAVE_RETAKE_ANSWER,
  SET_RESUBMISSION,
  SAVE_PREVIOUS_SURVEY_NETWORK,
  CLEAR_RETAKE,
  CLEAR_STATUS,
  OPEN_MODAL,
  CLOSE_MODAL,
  SAVE_CONSENT,
  PRUNE_CONNECTION,
} from './actionTypes';

const addSurveys = (surveys) => ({
  type: ADD_SURVEYS_FOR_TRAINER,
  surveys,
});

const addPerson = (person) => ({
  type: ADD_PERSON,
  id: person.id || v4(),
  name: person.name,
  external: person.external || false,
  relationship: null, // QUICKFIX, move this to a reducer or depth component initialization
});

const addFullPerson = (person) => ({
  ...person,
  type: ADD_PERSON,
  id: person.id || v4(),
  name: person.name,
  external: person.external || false,
  relationship: person.relationship || null, // QUICKFIX, move this to a reducer or depth component initialization
});

const removeAllPersons = () => ({
  type: CLEAR_STATUS,
});

const removePerson = (id) => ({ type: REMOVE_PERSON, id });
const updatePerson = (id, field, value) => ({
  type: UPDATE_PERSON,
  id,
  field,
  value,
});
const updateAllPerson = (person) => ({
  ...person,
  type: UPDATE_ALL_PERSON,
});

const addConnection = (id, otherPerson) => ({
  type: ADD_CONNECTION,
  id,
  otherPerson,
});
const removeConnection = (id, otherPerson) => ({
  type: REMOVE_CONNECTION,
  id,
  otherPerson,
});

const pruneConnection = (id, otherPerson) => ({
  type: PRUNE_CONNECTION,
  id,
  otherPerson,
});

const addStakeholder = (id) => ({ type: ADD_STAKEHOLDER, id });
const removeStakeholder = (id) => ({ type: REMOVE_STAKEHOLDER, id });

const addAdditionalStakeholder = (name) => ({
  type: ADD_ADDITIONAL_STAKEHOLDER,
  name,
});
const removeAdditionalStakeholder = (name) => ({
  type: REMOVE_ADDITIONAL_STAKEHOLDER,
  name,
});

const updateResults = (payload) => ({
  type: UPDATE_RESULTS,
  payload,
});

const addCohortCode = (payload) => ({ type: ADD_COHORT_CODE, payload });

const setActiveSurveyID = (payload) => ({
  type: SET_ACTIVE_SURVEY_ID,
  payload,
});

const addCohortStatistics = (cohortStatistics, cohortName) => ({
  type: ADD_COHORT_STATISTICS,
  cohortStatistics,
  cohortName,
});
const setCohortsList = (cohorts) => ({ cohorts, type: LIST_COHORTS });

const setSelectedCohort = (cohort) => ({ cohort, type: SELECT_COHORT });

const addCohortToStore = (cohort) => ({ cohort, type: ADD_COHORT_TO_STORE });

const updateCohortInStore = (cohort) => ({ cohort, type: UPDATE_COHORT_IN_STORE });

const removeCohortFromStore = (cohortId) => ({
  cohortId,
  type: REMOVE_COHORT_FROM_STORE,
});

const setSurveyCohort = (cohort) => ({
  type: SET_SURVEY_COHORT,
  cohort,
});

const setTotalCount = (totalCount) => ({ type: SET_SURVEY_COUNT, totalCount });

const removeSurveyCohort = () => ({
  type: REMOVE_SURVEY_COHORT,
});

const setUser = (user) => ({
  type: SET_USER,
  user,
});

const setIsSavingSurvey = (isSavingSurvey) => ({
  type: SET_IS_SAVING_SURVEY,
  isSavingSurvey,
});

const setCohortCount = (cohortCount) => ({
  type: SET_COHORT_COUNT,
  cohortCount,
});

const setCohortsCurrentPage = (currentPage) => ({
  type: SET_COHORTS_CURRENT_PAGE,
  currentPage,
});

const setCohortsOrder = (order) => ({
  type: SET_COHORTS_ORDER,
  order,
});

const setCohortsSearchTerm = (searchTerm) => ({
  type: SET_COHORTS_SEARCH_TERM,
  searchTerm,
});

const setIsCohortsModalOpen = (isModalOpen, isUpdateModal) => ({
  type: SET_IS_COHORTS_MODAL_OPEN,
  isModalOpen,
  isUpdateModal,
});

const setCohortsError = (isError, errorMsg = null) => ({
  type: SET_COHORTS_ERROR,
  isError,
  errorMsg,
});

const setOrganizationsList = (organizations) => ({
  organizations,
  type: LIST_ORGANIZATIONS,
});

const setOrganizationsCount = (organizationsCount) => ({
  type: SET_ORGANIZATIONS_COUNT,
  organizationsCount,
});

const setOrganizationsError = (isError, errorMsg = null) => ({
  type: SET_ORGANIZATIONS_ERROR,
  isError,
  errorMsg,
});

const setSelectedOrganization = (organization) => ({
  organization,
  type: SELECT_ORGANIZATION,
});

const setOrganizationsCurrentPage = (currentPage) => ({
  type: SET_ORGANIZATIONS_CURRENT_PAGE,
  currentPage,
});

const setOrganizationsOrder = (order) => ({
  type: SET_ORGANIZATIONS_ORDER,
  order,
});

const setOrganizationsSearchTerm = (searchTerm) => ({
  type: SET_ORGANIZATIONS_SEARCH_TERM,
  searchTerm,
});

const setIsOrganizationsModalOpen = (isModalOpen, isUpdateModal) => ({
  type: SET_IS_ORGANIZATIONS_MODAL_OPEN,
  isModalOpen,
  isUpdateModal,
});

const addOrganizationToStore = (organization) => ({
  organization,
  type: ADD_ORGANIZATION_TO_STORE,
});

const updateOrganizationInStore = (organization) => ({
  organization,
  type: UPDATE_ORGANIZATION_IN_STORE,
});

const removeOrganizationFromStore = (organizationId) => ({
  organizationId,
  type: REMOVE_ORGANIZATION_FROM_STORE,
});

const setIsContactsModalOpen = (isModalOpen) => ({
  type: SET_IS_CONTACTS_MODAL_OPEN,
  isModalOpen,
});

const setContactsList = (contacts) => ({
  contacts,
  type: LIST_CONTACTS,
});

const setContactsError = (isError, errorMsg = null) => ({
  type: SET_CONTACTS_ERROR,
  isError,
  errorMsg,
});

const setContactsIsFetching = (isFetching) => ({
  type: SET_CONTACTS_IS_FETCHING,
  isFetching,
});

const setContactsListIsOutdated = (isContactsListOutdated) => ({
  type: SET_CONTACTS_IS_OUTDATED,
  isContactsListOutdated,
});

const saveGoals = (goals) => ({
  type: SAVE_GOALS,
  goals,
});

const resetGoals = () => ({
  type: RESET_GOALS,
});

const savePreviousSurveyId = (id) => ({
  type: SAVE_PREVIOUS_SURVEY_ID,
  id,
});

const savePreviousNetwork = (network) => ({
  type: SAVE_PREVIOUS_SURVEY_NETWORK,
  network,
});

const setResubmission = (id) => ({
  type: SET_RESUBMISSION,
  id,
});

const saveUserGoals = (goals) => ({
  type: SAVE_USER_GOALS,
  goals,
});

const saveRetakeAnswer = (retakeAnswers) => ({
  type: SAVE_RETAKE_ANSWER,
  retakeAnswers,
});

const clearRetake = () => ({
  type: CLEAR_RETAKE,
});

const toggleModal = (payload) => ({
  type: OPEN_MODAL,
  payload,
});

const clearModal = () => ({
  type: CLOSE_MODAL,
});

const saveConsent = (consent) => ({
  type: SAVE_CONSENT,
  consent,
});

export {
  saveConsent,
  setTotalCount,
  addPerson,
  addFullPerson,
  removePerson,
  updatePerson,
  addConnection,
  removeConnection,
  addStakeholder,
  removeStakeholder,
  addAdditionalStakeholder,
  removeAdditionalStakeholder,
  updateResults,
  addCohortCode,
  setActiveSurveyID,
  addSurveys,
  addCohortStatistics,
  setCohortsList,
  setSelectedCohort,
  addCohortToStore,
  updateCohortInStore,
  removeCohortFromStore,
  setSurveyCohort,
  removeSurveyCohort,
  setUser,
  setIsSavingSurvey,
  setCohortCount,
  setCohortsCurrentPage,
  setCohortsOrder,
  setCohortsSearchTerm,
  setIsCohortsModalOpen,
  setCohortsError,
  setOrganizationsList,
  setOrganizationsCount,
  setOrganizationsError,
  setSelectedOrganization,
  setOrganizationsCurrentPage,
  setOrganizationsOrder,
  setOrganizationsSearchTerm,
  setIsOrganizationsModalOpen,
  addOrganizationToStore,
  updateOrganizationInStore,
  removeOrganizationFromStore,
  setResubmission,
  setIsContactsModalOpen,
  setContactsList,
  setContactsError,
  setContactsIsFetching,
  setContactsListIsOutdated,
  saveGoals,
  resetGoals,
  savePreviousSurveyId,
  savePreviousNetwork,
  saveUserGoals,
  saveRetakeAnswer,
  updateAllPerson,
  clearRetake,
  removeAllPersons,
  toggleModal,
  clearModal,
  pruneConnection,
};
