import React from 'react';
import emailIcon from 'public/assets/LND-website-email-icon.svg';
import { Container, Row, Col } from 'reactstrap';

export default function NeedHelp() {
  return (
    <Container fluid className="need_help">
      <Row className="max_desktop_width_60 pt-6">
        <Col>
          <h3>Need Help? Have Questions?</h3>
          <div className="help_email">
            <img src={emailIcon.src} alt="email" />
            <a href="mailto:info@NetworkLeader.com">info@NetworkLeader.com</a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
