// import Link from 'next/link';
// NOTE: Probably move to Link and Button when the purchase page is internal
import { Container, Row, Col } from 'reactstrap';
import { Hero } from 'components/landings';
import individualCourseIcon from 'public/assets/Individual_Course_Icon.svg';
import certifiedTrainerCourseIcon from 'public/assets/Certified_Trainer_Icon.svg';
import React from 'react';
import {
  lndIndividualCourseLink,
  lndCertifiedTrainerCourseLink,
} from 'lib/constants';
import * as constants from 'lib/constants';
import LandingButton from 'components/LandingButton';

function CourseCard({
  title,
  imgSrc,
  text1,
  text2,
  shopUrl,
  highlightColor,
}) {
  return (
    <Row>
      <Col style={{ border: constants[highlightColor], borderStyle: 'solid' }}>
        <Row className="p-4">
          <Col className="justify-content-center">
            <h3>{title}</h3>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col xs="12" className="px-0">
            <div className="d-flex" style={{ backgroundColor: constants[highlightColor] }}>
              <img
                style={{
                  height: '140px',
                  minWidth: '170px',
                  maxWidth: '200px',
                  margin: 'auto',
                  backgroundColor: constants[highlightColor],
                }}
                className="p-2"
                src={imgSrc}
                alt=""
              />
            </div>
          </Col>
        </Row>
        <Row className="px-4 pb-4">
          <Col xs="12" className="justify-content-center">
            <p className="text-center landing_paragraph_normal">{text1}</p>
            <p className="text-center landing_paragraph_normal">{text2}</p>
          </Col>
        </Row>
        <Row className="px-4 pb-4">
          <Col className="justify-content-center">
            <LandingButton color={highlightColor} url={shopUrl} text="Shop Now" newTab />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const individualCourseProps = {
  title: 'Individual Course',
  imgSrc: individualCourseIcon.src,
  text1:
    'Visualize, analyze and improve your professional network with a '
    + 'fully interactive and in-depth experience hosted on Adeption.',
  text2:
    'The course includes access to the diagnostic as well as action and '
    + 'reflection based workouts.',
  shopUrl: lndIndividualCourseLink,
  highlightColor: 'lndBlue',
};

const trainerCourseProps = {
  title: 'Certified Trainer',
  imgSrc: certifiedTrainerCourseIcon.src,
  text1:
    'Creators of the Leader Network Diagnostic walk you through delivering '
    + 'and debriefing the LND for your participants.',
  text2:
    'This includes access to the individual course, diagnostic, '
    + 'certified training, and trainer materials.',
  shopUrl: lndCertifiedTrainerCourseLink,
  highlightColor: 'lndOrange',
};

function TrainerPageLayout() {
  return (
    <Container fluid>
      <Hero
        intro="Leader Network Diagnostic"
        title="Online Course & Certification"
        color="#27B373"
      />
      <div className="max_desktop_width_70">
        <Row className="justify-content-center pb-4">
          <Col>
            <h2 className="text-center pt-3">
              Experiences that build on the Leader Network Diagnostic
            </h2>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col className="justify-content-center">
            <p className="text-center landing_paragraph_normal">
              Our self-paced online experiences help make the complex world of
              network research and analysis easy for anyone to understand and
              apply.
            </p>
            <p className="text-center landing_paragraph_normal">
              Our detailed courses are built on the Adeption learning platform
              which emphasizes action and reflection to bring the learning into
              practice. Join our network of leaders who’ve learned how to create
              more effective and resilient professional networks.
            </p>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col sm="6" xs="12" className="pb-4 px-5">
            <CourseCard {...individualCourseProps} />
          </Col>
          <Col sm="6" xs="12" className="pb-4 px-5">
            <CourseCard {...trainerCourseProps} />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default TrainerPageLayout;
