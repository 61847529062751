const computeDiversity = (data) => {
  // The following two arrays must be in view order
  const order = [
    'demographic',
    'geographic',
    'horizontal',
    'vertical',
    'stakeholder',
  ];
  const labels = [
    'Demographic',
    'Geographic',
    'Horizontal',
    'Vertical',
    'Stakeholder',
  ];
  return {
    data: order.map((key, index) => ({
      name: labels[index],
      value: data.individual[key],
    })),
    labels,
    percent: Math.round(data.overall),
  };
};

module.exports = computeDiversity;
