const ADMIN = 'Admin';
const TRAINER = 'Trainer';
const ORG_REP = 'OrgRep';

const ACCESS_TOKEN = 'accessToken';
const EXPIRES_AT = 'expiresAt';
const ID_TOKEN = 'idToken';
const ID_TOKEN_PAYLOAD = 'idTokenPayload';
const USER_ROLE = 'userRole';
const PATH_BEFORE_LOGIN = '__LND_pathBeforeLogin';

const ROLES = {
  ADMIN,
  TRAINER,
  ORG_REP,
};

const STORAGE = {
  ACCESS_TOKEN,
  EXPIRES_AT,
  ID_TOKEN,
  ID_TOKEN_PAYLOAD,
  PATH_BEFORE_LOGIN,
  USER_ROLE,
};

const TIME = Object.freeze({
  SECOND: 1_000,
  MINUTE: 60 * 1_000,
  HOUR: 60 * 60 * 1_000,
});

const MODAL_ACTIONS = Object.freeze({
  RELOAD: 'RELOAD',
});

const MODAL_ACTIONS_FN = {
  RELOAD: () => window.location.reload(),
};

export { ROLES, STORAGE, TIME, MODAL_ACTIONS, MODAL_ACTIONS_FN };
