const computeDepth = (data) => {
  // The following two arrays must be in view order
  const order = ['very_distant', 'not_close', 'close', 'very_close'];
  const labels = ['Very Shallow', 'Shallow', 'Deep', 'Very Deep'];

  const close = data.close + data.very_close;
  const distant = data.not_close + data.very_distant;
  const total = order.reduce((acc, label) => acc + data[label], 0);
  const percent = (x) => Math.round((x / total) * 100);

  const getLabel = () => {
    if (close >= 70) {
      return 'close';
    }
    if (distant >= 70) {
      return 'distant';
    }
    return 'balanced';
  };

  const label = getLabel();

  // This structure is a legacy of react-chartjs, could be made simpler
  // Would need to modify in report API endpoint and DepthChart.js
  const depth = {
    label,
    data: {
      labels,
      datasets: [
        {
          backgroundColor: 'rgba(27, 117, 186, 0.2)',
          borderColor: 'rgba(27, 117, 186, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(27, 117, 186, 0.4)',
          hoverBorderColor: 'rgba(27, 117, 186, 1)',
          data: order.map((key) => data[key]),
        },
      ],
    },
  };

  return { ...depth, percent: percent(close) };
};

module.exports = computeDepth;
