import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Hero, ModalVideo, ContentIcon } from 'components/landings';
import NWLDIcon from 'public/assets/Diagnostic-LND_logo_white.svg';
import EffectivenessIcon from 'public/assets/Diagnostic-Network_Effectiveness.svg';
import SuccessIcon from 'public/assets/Diagnostic-Network_Success.svg';
import SmalChangesIcon from 'public/assets/Diagnostic-Small_Changes.svg';
import Preview from 'public/assets/LND-OVerview THumbnail.png';
import TestMike from 'public/assets/Mike_Welsh.jpg';
import TestNick from 'public/assets/Nick_Petrie.jpg';
import TestCarylynn from 'public/assets/Carylynn_Larson.jpg';
import TestCarl from 'public/assets/CarlSanders_Edwards.jpg';
import { contactUsIndividualLink } from 'lib/constants';
import LandingButton from '../LandingButton';

function IconSection() {
  return (
    <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
      <Col className="d-flex align-items-center justify-content-center col-12 col-lg-4 pb-4">
        <ContentIcon
          icon={EffectivenessIcon}
          text="Assess the current effectiveness of your network"
        />
      </Col>
      <Col className="col-12 col-lg-4 pb-4">
        <ContentIcon
          icon={SuccessIcon}
          text="Discover how your professional network can help you succeed"
        />
      </Col>
      <Col className="col-12 col-lg-4 pb-3">
        <ContentIcon
          icon={SmalChangesIcon}
          text="Make small changes that have a big impact on your network"
        />
      </Col>
    </Row>
  );
}

function BulletSection() {
  return (
    <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
      <Col
        style={{ backgroundColor: 'rgb(43, 181, 115)' }}
        className="col-12 col-lg-4 d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0 py-6 px-3 highlight_image_col"
      >
        <img
          alt="img"
          className="pb-3"
          src={NWLDIcon.src}
          style={{ maxWidth: '250px' }}
        />
      </Col>
      <Col className="col-12 col-lg-8 d-flex flex-column align-items-start justify-content-center pt-2">
        <ul className="highlight_list landing_paragraph_normal ms-lg-5">
          <li className="landing_paragraph_normal ps-2 ps-sm-5 pb-4">
            Learn common networking myths
          </li>
          <li className="landing_paragraph_normal ps-2 ps-sm-5 pb-4">
            Understand the mindset of an effective networker{' '}
          </li>
          <li className="landing_paragraph_normal ps-2 ps-sm-5 pb-4">
            Discover invisible biases in your specific network
          </li>
          <li className="landing_paragraph_normal ps-2 ps-sm-5">
            Aquire frameworks for powerful networking strategies
          </li>
        </ul>
      </Col>
    </Row>
  );
}

function TestimonialsSection() {
  return (
    <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
      <Col className="col-12 pb-3">
        <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
          See what others are saying about the Leader Network Diagnostic
        </p>
      </Col>
      <Col className="col-12 col-lg-5 mb-5 me-lg-5">
        <img alt="img" className="w-100 pb-4" src={TestMike.src} />
        <p className="w-100 testimony_text">
          {' '}
          Michael Welsh - Leadership Effectiveness Manager, Facebook-Stripe-Hudson
        </p>
        <p className="w-100">
          “Completing the Leader Network Diagnostic was fascinating. The videos gave me a
          depth of understanding of the theory. The diagnostic led me to insights into my
          the strengths and weaknesses of my network exist and to clear actionable
          insights of how to improve my network.”
        </p>
      </Col>
      <Col className="col-12 col-lg-5 mb-5">
        <img alt="img" className="w-100 pb-4" src={TestCarl.src} />
        <p className="w-100 testimony_text">
          Carl Sanders-Edwards -CEO/Founder, Adeption
        </p>
        <p className="w-100">
          “I fundamentally changed my network after the first time I mapped my connections
          and have tweaked it a number of times. The benefits were immediate and tangible.
          We are actively promoting this tool for our leadership development partners and
          they universally love it and benefit just like I did.”
        </p>
      </Col>
      <Col className="col-12 col-lg-5 mb-5 me-lg-5">
        <img alt="img" className="w-100 pb-4" src={TestCarylynn.src} />
        <p className="w-100 testimony_text">
          {' '}
          Carylynn Larson, Ph.D., ACC - Leadership Coach, Author, Speaker.
          CreatingOpenSpace.org
        </p>
        <p className="w-100">
          “The Leader Network Diagnostic is a fantastic tool that helps leaders identify
          the relationships they need to foster and maintain in order to accomplish their
          goals. When clients realize that they don’t have the relationships in place to
          accomplish a given individual, team or corporate goal, they prioritize
          conversations and collaboration. Priceless.”
        </p>
      </Col>
      <Col className="col-12 col-lg-5 mb-5">
        <img alt="img" className="w-100 pb-4" src={TestNick.src} />
        <p className="w-100 testimony_text">Nick Petrie -Executive Development</p>
        <p className="w-100">
          “30 minutes using this tool changed the way I think about networks and made me a
          better leader. This is a tool that every leader should use if they care about
          improving their effectiveness.”
        </p>
      </Col>
    </Row>
  );
}

function VideoSection() {
  return (
    <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
      <Col className="col-12 col-lg-6">
        <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
          Learn who is really in your network, what’s working, and how you can improve
          your network through the data visualization and practical steps provided in the
          Leader Network Diagnostic.
        </p>
      </Col>
      <Col className="landing_display d-flex justify-content-center col-12 col-lg-5">
        <ModalVideo
          previewImage={Preview}
          videoUrl="https://vimeo.com/networkleader/overview"
        />
      </Col>
    </Row>
  );
}

function LndDescriptionSection() {
  return (
    <>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="d-flex justify-content-center col-lg-11 pb-4">
          <p className="text-center landing_paragraph_normal landing_paragraph_normal">
            The Leader Network Diagnostic is a flexible, fully digital assessment that can
            be delivered and debriefed in a variety of different ways. Contact us to see
            how we can tailor our diagnostic platform to your needs.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12">
          <LandingButton
            color="lndOrange"
            url={contactUsIndividualLink}
            text="Contact Us"
            newTab
          />
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="d-flex justify-content-center col-12 col-lg-11 pb-4">
          <p className="text-center landing_paragraph_normal">
            The Leader Network Diagnostic is a 20-minute individual assessment that will
            help you visualize and understand your professional network. The digital
            assessment can be taken from any device and includes comprehensive videos that
            guide you through the experience, explaining questions and concepts.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12 col-lg-11">
          <p className="text-center landing_paragraph_normal">
            At Network Leader, we’ve developed a scientific, leader-specific method
            designed to help you examine and improve your professional network. Our
            network solutions include a comprehensive diagnostic tool, leadership
            training, online training videos, and more.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default function DiagnosticPageLayout() {
  return (
    <Container fluid className="landing-container">
      <Hero intro="Discover the" title="LEADER NETWORK DIAGNOSTIC" color="#2bb573" />
      <VideoSection />
      <LndDescriptionSection />
      <TestimonialsSection />
      <IconSection />
      <BulletSection />
    </Container>
  );
}
