import React from 'react';
import { Row, Col } from 'reactstrap';

export default function Hero() {
  return (
    <div className="hero">
      <Row className="hero_row max_desktop_width_60">
        <Col className="hero_col col-12 ">
          <div className="pt-3 ">
            <h2 className="hero_no_margin_text m-0">
              The top predictor of career success is
            </h2>
            <h2 className="hero_bold_text">your core professional network</h2>
            <p className="hero_small_text">
              - Forbes / Harvard Business Review
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
