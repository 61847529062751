import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

export default function Hero({ intro, title, color }) {
  return (
    <Row
      style={{ backgroundColor: color }}
      className="landing_hero  mb-4 mb-lg-4 mt-4 mt-lg-4"
    >
      <Col className="col-12 max_desktop_width_70 py-2">
        <div className="ps-md-2 d-flex flex-column align-items-start py-2">
          <h1 className="landing_hero_role_text">{intro}</h1>
          <h2 className="landing_hero_dashboard_text text-justify">{title}</h2>
        </div>
      </Col>
    </Row>
  );
}

Hero.propTypes = {
  intro: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
