import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const EDIT_CONTACT = gql`
  mutation addContact($contact: ContactInput!, $cohortId: ID!) {
    addContact(contact: $contact, cohortId: $cohortId)
  }
`;

const DELETE_CONTACT = gql`
  mutation deleteContact($id: ID!, $cohortId: ID!) {
    deleteContact(id: $id, cohortId: $cohortId)
  }
`;

const useContact = () => {
  const [addContact, { loading: addContactLoading, error: addContactError }] =
    withSessionHandler(useMutation(EDIT_CONTACT));

  const [deleteContact, { loading: deleteContactLoading, error: deleteContactError }] =
    withSessionHandler(useMutation(DELETE_CONTACT));

  return {
    addContact,
    addContactLoading,
    addContactError,
    deleteContactLoading,
    deleteContactError,
    deleteContact,
  };
};

export default useContact;
