import React from 'react';
import LandingButton from 'components/LandingButton';
import PropTypes from 'prop-types';

export default function ContentIcon({
  maxTextWidth,
  icon,
  text,
  subtext,
  learnMore,
  learnMoreUrl,
  color,
  newTab,
}) {
  const buttonProps = {};
  if (newTab) {
    buttonProps.target = '_blank';
    buttonProps.rel = 'noopener noreferrer';
  }
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img
        style={{ height: '110px', minWidth: '170px', maxWidth: '200px' }}
        className="content_icon pb-3"
        src={icon.src}
        alt="icon"
      />
      {typeof text === 'string' ? (
        <p
          className="text-center landing_paragraph_normal"
          style={{ maxWidth: maxTextWidth }}
        >
          {text}
        </p>
      ) : (
        text
      )}
      {subtext
        && (typeof subtext === 'string' ? (
          <p className="text-center">{subtext}</p>
        ) : (
          subtext
        ))}
      {learnMore && (
        <LandingButton color={color} url={learnMoreUrl} text="Learn More" />
      )}
    </div>
  );
}

ContentIcon.propTypes = {
  maxTextWidth: PropTypes.string,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  learnMore: PropTypes.bool.isRequired,
  learnMoreUrl: PropTypes.string.isRequired,
  color: PropTypes.string,
  newTab: PropTypes.string.isRequired,
};

ContentIcon.defaultProps = {
  maxTextWidth: '350px',
  color: '',
};
