import React from 'react';
import { Row, Col } from 'reactstrap';
import PartnersWellbeingLifePreserverIcon from 'public/assets/Partners-Wellbeing_Life_Preserver.svg';
import ContactUs from './ContactUs';

export default function WellbeingSection() {
  return (
    <>
      <Row
        id="wellbeing"
        className="max_desktop_width_80 d-flex justify-content-center pb-5 pt-5"
      >
        <Col className="wellbeing_img d-flex flex-column justify-content-center col-12 col-lg-6" />
        <Col
          style={{ backgroundColor: 'rgba(41, 179, 115, .5)' }}
          className="d-flex justify-content-center col-12 col-lg-6"
        >
          <div className="p-5 pe-lg-6">
            <h2 className="text-start fw-bold pb-4">Wellbeing</h2>
            <h4 className="pb-4">
              Ensure your talent has the social support to stay resilient
            </h4>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://psycnet.apa.org/record/2016-54984-003"
              >
                Networks are conduits of vital resources that promote worker
                wellbeing
              </a>
              . The LND provides HR and organizational leaders with a snapshot
              of the social wellbeing of their talent.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="justify-content-center col-12 col-lg-5 pb-4 pb-lg-0">
          <h3
            className="text-center pb-5"
            style={{
              maxWidth: '25rem',
              margin: 'auto',
              color: 'rgb(41, 179, 115)',
            }}
          >
            The LND ensures employees are investing in the right connections to
            help support them through tough times.
          </h3>
          <p className="landing_paragraph_normal">
            Although the LND is professionally oriented, we ask questions about
            social support and friendship connections because they have a huge
            impact on personal wellbeing, especially during tough times or
            periods of instability.
          </p>
        </Col>
        <Col className="col-lg-1" />
        <Col className="justify-content-center col-12 col-lg-5">
          <img className="pb-5" src={PartnersWellbeingLifePreserverIcon.src} />
          <p className="landing_paragraph_normal pb-4">
            HR leaders can utilize levels of these network indicators to inform
            the development and implementation of wellbeing support initiatives.
            Individuals can use the feedback from the LND to better understand
            the support resources available to them through their networks, and
            how they need to build relationships to improve their wellbeing.
          </p>
        </Col>
      </Row>
      <ContactUs
        text="We can help your employees recognize their social support"
        color="lndGreen"
      />
    </>
  );
}
