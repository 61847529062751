import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const SAVE_GOALS = gql`
  mutation addGoalsSurvey(
    $goals: GoalInput!
    $user: UserInput
    $source: SourceInput
    $id: ID
    $cohort_id: ID
    $surveyId: ID
    $completed: Boolean
  ) {
    addGoalsSurvey(
      goals: $goals
      user: $user
      source: $source
      id: $id
      cohort_id: $cohort_id
      surveyId: $surveyId
      completed: $completed
    )
  }
`;

const GET_LAST_GOAL = gql`
  query lastGoal($cohortId: String!) {
    lastGoal(cohortId: $cohortId) {
      _id
      created
      surveyId
      completed
      goals {
        challenges
        goals_self
        goals_team
        feedback
        focus {
          key
          extra
          description
        }
      }
      cohort_id
      user {
        id
        email
        name
        licenses
        userId
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_SURVEY_GOAL = gql`
  query getSurveyGoals($surveyId: String!) {
    getSurveyGoals(surveyId: $surveyId) {
      _id
      created
      surveyId
      completed
      goals {
        challenges
        goals_self
        goals_team
        feedback
        focus {
          key
          extra
          description
        }
      }
      cohort_id
      user {
        id
        email
        name
        licenses
        userId
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_GOAL_BY_COHORT = gql`
  query cohortGoals($cohortId: String!) {
    cohortGoals(cohortId: $cohortId) {
      goals {
        _id
        created
        surveyId
        completed
        goals {
          challenges
          goals_self
          goals_team
          feedback
          focus {
            key
            extra
            description
          }
        }
        cohort_id
        user {
          id
          email
          name
          licenses
          userId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_GOALS_BY_USER = gql`
  query userGoals {
    userGoals {
      goals {
        _id
        created
        surveyId
        completed
        goals {
          challenges
          goals_self
          goals_team
          feedback
          focus {
            key
            extra
            description
          }
        }
        cohort_id
        user {
          id
          email
          name
          licenses
          userId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_GOALS_BY_USER_AND_COHORT = gql`
  query getUserGoalsByCohort($email: String!, $cohort: String!) {
    getUserGoalsByCohort(email: $email, cohort: $cohort) {
      goals {
        _id
        created
        surveyId
        completed
        goals {
          challenges
          goals_self
          goals_team
          feedback
          focus {
            key
            extra
            description
          }
        }
        cohort_id
        user {
          id
          email
          name
          licenses
          userId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const useGoals = () => {
  const getLastGoal = ({ cohortId }) => {
    const doc = withSessionHandler(
      useQuery(GET_LAST_GOAL, {
        variables: {
          cohortId,
        },
        skip: !cohortId,
      })
    );
    return doc.data;
  };

  const getSurveyGoals = ({ surveyId }) => {
    const doc = withSessionHandler(
      useQuery(GET_SURVEY_GOAL, {
        variables: {
          surveyId,
        },
        skip: !surveyId,
      })
    );
    return doc.data;
  };

  const getGoalsByCohort = (cohortId) => {
    const doc = withSessionHandler(
      useQuery(GET_GOAL_BY_COHORT, {
        variables: {
          cohortId,
        },
        fetchPolicy: 'no-cache', // Cache was causing items to be the copies
        skip: !cohortId,
      })
    );
    return doc.data;
  };

  const getGoalsByUserAndCohort = ({ cohort, email }) => {
    const doc = withSessionHandler(
      useQuery(GET_GOALS_BY_USER_AND_COHORT, {
        variables: {
          email,
          cohort,
        },
        fetchPolicy: 'no-cache',
        skip: !cohort,
      })
    );
    return { loading: doc.loading, data: doc?.data?.getUserGoalsByCohort };
  };

  const getUserGoals = () => {
    const doc = withSessionHandler(
      useQuery(GET_GOALS_BY_USER, {
        fetchPolicy: 'no-cache',
      })
    );
    return { result: doc.data, loading: doc.loading };
  };

  const [addGoalsSurvey] = withSessionHandler(useMutation(SAVE_GOALS));

  return {
    addGoalsSurvey,
    getLastGoal,
    getGoalsByCohort,
    getUserGoals,
    getGoalsByUserAndCohort,
    getSurveyGoals,
  };
};

export default useGoals;
