import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_COHORT = gql`
  query cohort($cohort: String!) {
    cohort(cohort: $cohort) {
      id
      name
      trainers
      created
      contacts
      options {
        immediate_flow
        individual_purchase
        price
        expires_at
        max_participants
        includes_retake
        demographicQuestions
        contactsLimited
        enableTrainersViewReport
        testCohort
      }
      surveysCount
    }
  }
`;

const GET_ORG_COHORTS = gql`
  query getOrgCohorts($orgId: String!) {
    getOrgCohorts(orgId: $orgId) {
      cohorts {
        id
        name
      }
    }
  }
`;

const IS_ORG_REP_OF_COHORT = gql`
  query isOrgRepOfCohort($cohortId: String!) {
    isOrgRepOfCohort(cohortId: $cohortId) {
      id
    }
  }
`;

const GET_COHORTS_FOR_ADMIN = gql`
  query adminCohorts {
    adminCohorts {
      orgs {
        id
        name
      }
      cohorts {
        id
        name
        organizationId
        created
      }
    }
  }
`;

const SAVE_COHORT_EMAIL_SEQUENCE = gql`
  mutation addEmailSequence(
    $emailSequenceOptions: EmailSequenceOptionsInput
    $cohortId: ID
  ) {
    addEmailSequence(emailSequenceOptions: $emailSequenceOptions, cohortId: $cohortId)
  }
`;

const useCohort = () => {
  const [
    addEmailSequence,
    { loading: addEmailSequenceLoading, error: addEmailSequenceError },
  ] = withSessionHandler(useMutation(SAVE_COHORT_EMAIL_SEQUENCE));

  const getCohort = ({ cohort }) => {
    const doc = withSessionHandler(
      useQuery(GET_COHORT, {
        variables: {
          cohort,
        },
        skip: !cohort,
      })
    );
    return { loading: doc.loading, result: doc.data };
  };

  const getOrgCohorts = ({ orgId }) => {
    const doc = withSessionHandler(
      useQuery(GET_ORG_COHORTS, {
        variables: {
          orgId,
        },
        skip: !orgId,
        fetchPolicy: 'no-cache',
      })
    );
    return { loading: doc.loading, result: doc.data };
  };

  const isOrgRepOfCohort = ({ cohortId }) => {
    const doc = withSessionHandler(
      useQuery(IS_ORG_REP_OF_COHORT, {
        variables: {
          cohortId,
        },
        skip: !cohortId,
        fetchPolicy: 'no-cache',
      })
    );
    console.log(doc);
    return { loading: doc.loading, result: doc.data };
  };

  const getCohortsForAdmin = () => {
    const doc = withSessionHandler(
      useQuery(GET_COHORTS_FOR_ADMIN, { fetchPolicy: 'no-cache' })
    );
    return { loading: doc.loading, result: doc.data };
  };

  return {
    getCohort,
    addEmailSequence,
    addEmailSequenceLoading,
    addEmailSequenceError,
    getCohortsForAdmin,
    getOrgCohorts,
    isOrgRepOfCohort,
  };
};

export default useCohort;
