import InitialSection from './InitialSection';
import KeyTalentSection from './KeyTalentSection';
import BelongingSection from './BelongingSection';
import WellbeingSection from './WellbeingSection';
import InnovationSection from './InnovationSection';
import LeadershipSection from './LeadershipSection';
import ScrollToTop from './ScrollToTop';

export {
  InitialSection,
  KeyTalentSection,
  BelongingSection,
  WellbeingSection,
  InnovationSection,
  LeadershipSection,
  ScrollToTop,
};
