import React, { useState, createContext, useMemo } from 'react';
import { Alert } from 'reactstrap';

const initialContext = {
  setMessage: () => {},
};

export const ToastContext = createContext(initialContext);

const DELAY = 3_000;

const ToastProvider = ({ children }) => {
  const [msg, setMsg] = useState(null);
  const [color, setColor] = useState('success');

  const setMessage = useMemo(
    () => ({
      setMessage: (message, type = 'success') => {
        setMsg(message);
        setColor(type);
        setTimeout(() => setMsg(null), DELAY);
      },
    }),
    [setMsg]
  );

  return (
    <ToastContext.Provider value={setMessage}>
      {children}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 1100,
        }}
      >
        <Alert isOpen={!!msg} color={color} style={{ marginLeft: '1rem' }}>
          {msg}
        </Alert>
      </div>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
