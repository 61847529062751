import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_REMINDERS = gql`
  query getReminders {
    getReminders {
      reminders {
        type
        cohortId
      }
    }
  }
`;

const useReminders = () => {
  const getReminders = () => {
    const doc = withSessionHandler(
      useQuery(GET_REMINDERS, {
        fetchPolicy: 'no-cache',
      })
    );
    return { loading: doc.loading, result: doc.data, errors: doc.error };
  };

  return { getReminders };
};

export { useReminders, GET_REMINDERS };
