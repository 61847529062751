import pino from 'pino';
import { logflarePinoVercel } from 'pino-logflare';

const { stream, send } = logflarePinoVercel({
  apiKey: process.env.LOGFLARE_KEY,
  sourceToken: process.env.LOGFLARE_SRC,
});

const serializeLog = (o) => {
  const obj = { ...o };
  delete obj.time;
  delete obj.level;
  delete obj.msg;
  return obj;
};

const logger = pino(
  {
    browser: {
      write: {
        debug: (o) =>
          process.env.NODE_ENV === 'development' && console.log(serializeLog(o), o.msg),
        info: (o) =>
          process.env.NODE_ENV === 'development' && console.log(serializeLog(o), o.msg),
      },
      transmit: {
        level: 'debug',
        send: (level, logEvent) => {
          if (process.env.NODE_ENV !== 'development') send(level, logEvent);
        },
      },
    },
    level: 'debug',
    base: {
      env: process.env.NODE_ENV,
      revision: process.env.VERCEL_GIT_COMMIT_SHA,
    },
  },
  stream
);

export default logger;
