import { useState, useEffect } from 'react';

export default function useIsLoading() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading;
}
