import { SAVE_RETAKE_ANSWER } from '../actions/actionTypes';

const retakeAnswers = (state = {}, action) => {
  let newState = state;
  switch (action.type) {
    case SAVE_RETAKE_ANSWER:
      newState = { ...state, ...action.retakeAnswers };
      return newState;
    default:
      return newState;
  }
};

export default retakeAnswers;
