import { ADD_STAKEHOLDER, REMOVE_PERSON, REMOVE_STAKEHOLDER } from '../actions/actionTypes';

const stakeholders = (state = [], action) => {
  switch (action.type) {
    case ADD_STAKEHOLDER:
      return [action.id, ...state];
    case REMOVE_PERSON:
    case REMOVE_STAKEHOLDER:
      return state.filter((stakeholder) => stakeholder !== action.id);
    default:
      return state;
  }
};

export default stakeholders;
