import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import TestimonialVideosLayout from './TestimonialVideosLayout';

export default function LandingTestimonialsSection() {
  return (
    <Container fluid className="testimonials_section px-0">
      <Row className="testimonial_row max_desktop_width_60 pt-6">
        <Col className="col-12">
          <h3 className="testimonial_header">
            The Leader Network Diagnostic leverages over 40 years of scientific
            {' '}
            <br className="desktop_br" />
            research to effectively analyze key aspects of your network.
          </h3>
        </Col>
      </Row>
      <Row className="testimonial_videos_row max_desktop_width_60 pt-6 pb-5">
        <TestimonialVideosLayout />
      </Row>
    </Container>
  );
}
