import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Hero, ContentIcon } from 'components/landings';
import Trainer from 'public/assets/Trainer-Presenter.svg';
import Collaboration from 'public/assets/Trainer-Collaboration.svg';
import Influence from 'public/assets/Trainer-Influence.svg';
import Innovation from 'public/assets/Trainer-Innovation.svg';
import { contactUsTrainerLink } from 'lib/constants';
import LandingButton from 'components/LandingButton';

export default function TrainerPageLayout() {
  return (
    <Container fluid>
      <Hero intro="Become a" title="CERTIFIED TRAINER" color="#F59120" />
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-6">
        <Col className="col-12 ">
          <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
            Help others
            {' '}
            <strong>leverage the full power of their networks</strong>
            {' '}
            by
            becoming a Certified Trainer of the Leader Network Diagnostic
          </p>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-7">
        <Col className="d-flex justify-content-center  pb-6">
          <p className="text-center landing_paragraph_normal">
            Human Resources professionals, leadership trainers, executive
            coaches, university professors, network scholars, and more have used
            our training and development program to help individuals build
            networks for future success. We’ve designed the Leader Network
            Diagnostic to facilitate much of the technical learning, allowing
            you to focus on facilitating conversations among participants that
            foster their growth and development.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12 pb-4">
          <p className="text-center landing_paragraph_normal">
            The Leader Network Diagnostic is a flexible, fully digital
            assessment that can be delivered and debriefed in a variety of
            different ways. Contact us to see how we can tailor our diagnostic
            platform to your needs.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12 ">
          <LandingButton color="orange" url={contactUsTrainerLink} text="Contact Us" newTab />
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-6 pb-lg-7">
        <Col
          style={{ backgroundColor: '#F59120' }}
          className="col-12 col-lg-5 d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0 py-6 px-3 highlight_image_col"
        >
          <img
            className="pb-3"
            src={Trainer.src}
            style={{ maxWidth: '200px' }}
          />
        </Col>
        <Col className="col-12 col-lg-7 d-flex flex-column align-items-start justify-content-center pt-2">
          <ul className="highlight_list landing_paragraph_normal ms-lg-5">
            <li className="landing_paragraph_normal ps-2 ps-sm-4 pb-4">
              Detailed framework on how to deliver the material
            </li>
            <li className="landing_paragraph_normal ps-2 ps-sm-4 pb-4">
              Training videos from Phil Willburn, creator of the diagnostic
            </li>
            <li className="landing_paragraph_normal ps-2 ps-sm-4 pb-4">
              Comprehensive FAQ with cited references and studies
            </li>
            <li className="landing_paragraph_normal ps-2 ps-sm-4">
              Ongoing access to updates and new research
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-6">
        <Col className="col-12 pb-5">
          <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
            As a Certified Trainer, you will
            {' '}
            <strong>help your organization</strong>
            {' '}
            improve:
          </p>
        </Col>
        <Col className="d-flex align-items-center justify-content-center col-12 col-lg-4 pb-5">
          <ContentIcon
            icon={Collaboration}
            text={<strong>Collaboration</strong>}
            subtext="Break down silos"
          />
        </Col>
        <Col className="col-12 col-lg-4 pb-5">
          <ContentIcon
            icon={Innovation}
            text={<strong>Innovation</strong>}
            subtext="Solving complex problems"
          />
        </Col>
        <Col className="col-12 col-lg-4 pb-3">
          <ContentIcon
            icon={Influence}
            text={<strong>Influence</strong>}
            subtext="Creating aligment"
          />
        </Col>
      </Row>
      <Row>
        <Col className="max_desktop_width_70 d-flex justify-content-center pb-7">
          <p className="lead d-flex align-items-center">
            Already a trainer?
            <LandingButton color="lndOrange" url="/trainer" text="Go to the Trainer Page" newTab={false} />
          </p>
        </Col>
      </Row>
    </Container>
  );
}
