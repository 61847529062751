import React from 'react';
import NetworkLeaderLogoImg from 'public/assets/LND-Network-Leader-logo.svg';

export default function NetworkLeaderLogo() {
  return (
    <div className="network_icon ms-1">
      <img
        className="network_icon_image"
        src={NetworkLeaderLogoImg.src}
        alt="Network Leader"
      />
    </div>
  );
}
