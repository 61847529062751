import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Hero, ContentIcon } from 'components/landings';
import ResearchIcon from 'public/assets/Research-Our_Approach.svg';
import ResearchScience from 'public/assets/Research-The_Science.svg';
import ResearchAnalytical from 'public/assets/Research-Analytical_Data.svg';
import ResearchPromoted from 'public/assets/Research-Promoted_Rapidly.svg';
import ResearchBonuses from 'public/assets/Research-Higher_Bonuses.svg';
import ResearchNewSituations from 'public/assets/Research-New_Situations.svg';
import ResearchChange from 'public/assets/Research-Lead_Change.svg';
import ResearchInfluential from 'public/assets/Research-Influential.svg';
import ResarchTop from 'public/assets/Research-20percent_Leaders.svg';
import { contactUsIndividualLink } from 'lib/constants';
import LandingButton from 'components/LandingButton';

export default function ResearchPageLayout() {
  return (
    <Container fluid>
      <Hero intro="Learn about our" title="RESEARCH" color="#652F8E" />
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="col-12 ">
          <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
            We provide a
            {' '}
            <strong>scientific and analytical approach</strong>
            {' '}
            to
            understanding your professional network.
          </p>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="d-flex justify-content-center  pb-4">
          <p className="text-center landing_paragraph_normal landing_paragraph_normal">
            Network Leader’s approach has helped more than 20,000 leaders
            efficiently and scientifically map, analyze, and improve their
            professional networks. Our assessments are consumable and
            actionable, created by academics and scientists, but designed for
            everyday working professionals.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12 ">
          <LandingButton color="orange" url={contactUsIndividualLink} text="Contact Us" newTab />
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="d-flex justify-content-center col-12 col-lg-11 pb-4">
          <p className="text-center landing_paragraph_normal">
            The Leader Network Diagnostic is a 20-minute individual assessment
            that will help you visualize and understand your professional
            network. The digital assessment can be taken from any device and
            includes comprehensive videos that guide you through the experience,
            explaining questions and concepts.
          </p>
        </Col>
        <Col className="d-flex justify-content-center col-12 col-lg-11">
          <p className="text-center landing_paragraph_normal">
            At Network Leader, we’ve developed a scientific, leader-specific
            method designed to help you examine and improve your professional
            network. Our network solutions include a comprehensive diagnostic
            tool, leadership training, online training videos, and more.
          </p>
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
        <Col className="d-flex align-items-center justify-content-center col-12 col-lg-5 pb-4 mt-lg-3">
          <ContentIcon
            icon={ResearchScience}
            maxTextWidth="500px"
            text={(
              <p className="landing_paragraph_normal text-center">
                <strong>The Science:</strong>
                {' '}
                Our method draws from years of
                management and psychology research to apply empirically-vetted
                knowledge and best practices to leaders’ networks around the
                globe (read more about it
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cclinnovation.org/wp-content/uploads/2020/02/networkshowcollective.pdf"
                >
                  here
                  {' '}
                </a>
                and
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://journals.sagepub.com/doi/10.1177/1052562915624124"
                >
                  here
                </a>
                ).
              </p>
            )}
          />
        </Col>
        <Col className="col-12 col-lg-5 mt-lg-6 pb-4">
          <ContentIcon
            icon={ResearchAnalytical}
            maxTextWidth="500px"
            text={(
              <p className="landing_paragraph_normal text-center">
                <strong>The Analytical Data:</strong>
                {' '}
                Our data-based approach
                allows you to see hidden patterns that impact your
                effectiveness. Similar to the analytical methodologies used by
                the intelligence community, Network Leader’s approach provides
                context for how relationships actually function together, as
                opposed to how we think they do.
              </p>
            )}
          />
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
        <Col
          style={{ backgroundColor: '#652F8E' }}
          className="col-12 col-lg-5 d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0 py-5 px-3 highlight_image_col"
        >
          <img
            className="pb-3"
            src={ResearchIcon.src}
            style={{ maxWidth: '150px' }}
            alt=""
          />
          <p className="landing_paragraph_normal text-center">
            Our approach is tested
            <br />
            and proven
          </p>
        </Col>
        <Col className="col-12 col-lg-7 d-flex flex-column align-items-start justify-content-center pt-2">
          <p className="text-center landing_paragraph_normal landing_paragraph_normal ms-lg-4">
            Network Leader&apos;s method draws from decades of research on
            professional networks to transform leaders by helping them build and
            capitalize on their networks. The research shows this approach can
            help people from all walks of life; different educational
            backgrounds, levels of leadership, and from all around the globe.
            90% of people surveyed after taking the LND said this dramatically
            improved their organizational effectiveness.
          </p>
        </Col>
      </Row>
      {' '}
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
        <Col className="col-12 pb-4">
          <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
            <strong>Over 40 years of network science research</strong>
            {' '}
            shows
            that leaders with effective networks are:
          </p>
        </Col>
        <Col className="d-flex align-items-center justify-content-center col-12 col-lg-4 mb-6">
          <ContentIcon
            icon={ResearchPromoted}
            text="Promoted more rapidly"
            learnMore
            learnMoreUrl="https://www.hup.harvard.edu/catalog.php?isbn=9780674843714"
            color="purple"
            newTab
          />
        </Col>
        <Col className="col-12 col-lg-4 mb-6">
          <ContentIcon
            icon={ResearchBonuses}
            text="Recieve higher bonuses"
            learnMore
            learnMoreUrl="https://www.bebr.ufl.edu/sites/default/files/Burt%20(2001)%20Structural%20holes%20versus%20network%20closure.pdf"
            color="green"
            newTab
          />
        </Col>
        <Col className="col-12 col-lg-4 mb-6">
          <ContentIcon
            icon={ResearchNewSituations}
            text="Adapt to new situations quickly"
            learnMore
            learnMoreUrl="https://pinnacle.allenpress.com/doi/abs/10.1509/jimk.18.4.58"
            color="purple"
            newTab
          />
        </Col>
        <Col className="col-12 col-lg-4 mb-6">
          <ContentIcon
            icon={ResearchChange}
            text="Lead change better"
            learnMore
            learnMoreUrl="https://hbr.org/2013/07/the-network-secrets-of-great-change-agents"
            color="green"
            newTab
          />
        </Col>
        <Col className="col-12 col-lg-4 mb-6">
          <ContentIcon
            icon={ResearchInfluential}
            text="Viewed as more influential"
            learnMore
            learnMoreUrl="http://www.fss.uu.nl/pubs/hflap/2001gettingstarted.pdf"
            color="purple"
            newTab
          />
        </Col>
        <Col className="col-12 col-lg-4 mb-6">
          <ContentIcon
            icon={ResarchTop}
            text="Viewed in top 20% of leaders"
            learnMore
            learnMoreUrl="https://www.sciencedirect.com/science/article/abs/pii/S0090261608000120"
            color="green"
            newTab
          />
        </Col>
      </Row>
    </Container>
  );
}
