/*
 * Google Analytics functions to be used in Next.js apps
 * pageView() should be run on route change and
 * event() can be used to track arbitrary client events
 */

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// Use this for page changes
const pageView = ({ url, ANALYTICS_TOKEN, isDev }) => {
  if (!isDev) {
    window.gtag('config', ANALYTICS_TOKEN, {
      page_location: url,
    });
  } else {
    return `Gtag ${url}`;
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// Use this for *any* other events you want to track
const event = ({ action, parameters, isDev }) => {
  if (!isDev) {
    window.gtag('event', action, parameters);
  }
};

module.exports = {
  pageView,
  event,
};
