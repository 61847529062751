import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

export const SAVE_SURVEY = gql`
  mutation saveSurvey(
    $survey: SurveyInput!
    $user: UserInput
    $source: SourceInput
    $id: ID
    $cohort_id: ID
    $previousSurveyId: ID
    $completed: Boolean
    $lastPage: String
  ) {
    saveSurvey(
      survey: $survey
      user: $user
      source: $source
      id: $id
      cohort_id: $cohort_id
      previousSurveyId: $previousSurveyId
      completed: $completed
      lastPage: $lastPage
    )
  }
`;

const useSaveSurvey = () => {
  const [saveSurvey] = withSessionHandler(useMutation(SAVE_SURVEY));
  return {
    saveSurvey,
  };
};

export default useSaveSurvey;
