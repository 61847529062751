import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-sessionstorage';
import rootReducer from 'lib/redux/reducers';

const initStore = (preloadedState) => {
  const composeEnhancers = composeWithDevTools({
    name: 'Network Leader',
    actionsBlacklist: [],
  });

  if (typeof window === 'undefined') {
    // We return an empty dummy store for SSR/Pre-Render
    // The store is recreated and populated from sessionStorage
    // on client-side load
    return createStore(() => ({}));
  }

  const engine = createEngine('lnd-survey');

  const store = createStore(
    storage.reducer(rootReducer),
    preloadedState,
    composeEnhancers(applyMiddleware(storage.createMiddleware(engine)))
  );

  const load = storage.createLoader(engine);

  load(store);

  return store;
};

export default initStore;
