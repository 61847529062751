import React from 'react';
import { Container } from 'reactstrap';
import { Hero } from 'components/landings';
import {
  InitialSection,
  KeyTalentSection,
  BelongingSection,
  WellbeingSection,
  InnovationSection,
  LeadershipSection,
  ScrollToTop,
} from './partners';

function PartnersPageLayout() {
  return (
    <Container id="partners" fluid className="landing-container">
      <Hero
        intro="Human Resources Leaders and"
        title="PARTNERS"
        color="#2bb573"
      />
      <InitialSection />
      <KeyTalentSection />
      <BelongingSection />
      <WellbeingSection />
      <InnovationSection />
      <LeadershipSection />
      <ScrollToTop />
    </Container>
  );
}

export default PartnersPageLayout;
