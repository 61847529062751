export const ADD_PERSON = 'ADD_PERSON';
export const REMOVE_PERSON = 'REMOVE_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_ALL_PERSON = 'UPDATE_ALL_PERSON';
export const SET_USER = 'SET_USER';

export const ADD_CONNECTION = 'ADD_CONNECTION';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const PRUNE_CONNECTION = 'PRUNE_CONNECTION';

export const ADD_STAKEHOLDER = 'ADD_STAKEHOLDER';
export const REMOVE_STAKEHOLDER = 'REMOVE_STAKEHOLDER';

export const ADD_ADDITIONAL_STAKEHOLDER = 'ADD_ADDITIONAL_STAKEHOLDER';
export const REMOVE_ADDITIONAL_STAKEHOLDER = 'REMOVE_ADDITIONAL_STAKEHOLDER';

export const SET_SURVEY_COUNT = 'SET_SURVEY_COUNT';

export const UPDATE_RESULTS = 'UPDATE_RESULTS';

export const ADD_COHORT_CODE = 'ADD_COHORT_CODE';

export const SET_ACTIVE_SURVEY_ID = 'SET_ACTIVE_SURVEY_ID';
export const ADD_SURVEYS_FOR_TRAINER = 'ADD_SURVEYS_FOR_TRAINER';

export const ADD_COHORT_STATISTICS = 'ADD_COHORT_STATISTICS';
export const LIST_COHORTS = 'LIST_COHORTS';
export const SET_COHORT_COUNT = 'SET_COHORT_COUNT';
export const SET_COHORTS_CURRENT_PAGE = 'SET_COHORTS_CURRENT_PAGE';
export const SET_COHORTS_ORDER = 'SET_COHORTS_ORDER';
export const SET_COHORTS_SEARCH_TERM = 'SET_COHORTS_SEARCH_TERM';
export const SELECT_COHORT = 'SELECT_COHORT';
export const ADD_COHORT_TO_STORE = 'ADD_COHORT_TO_STORE';
export const UPDATE_COHORT_IN_STORE = 'UPDATE_COHORT_IN_STORE';
export const REMOVE_COHORT_FROM_STORE = 'REMOVE_COHORT_FROM_STORE';
export const SET_SURVEY_COHORT = 'SET_SURVEY_COHORT';
export const REMOVE_SURVEY_COHORT = 'REMOVE_SURVEY_COHORT';
export const SET_IS_COHORTS_MODAL_OPEN = 'SET_IS_COHORTS_MODAL_OPEN';
export const SET_COHORTS_ERROR = 'SET_COHORTS_ERROR';

export const SET_IS_SAVING_SURVEY = 'SET_IS_SAVING_SURVEY';

export const LIST_ORGANIZATIONS = 'LIST_ORGANIZATIONS';
export const SET_ORGANIZATIONS_COUNT = 'SET_ORGANIZATIONS_COUNT';
export const SET_ORGANIZATIONS_ERROR = 'SET_ORGANIZATIONS_ERROR';
export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';
export const SET_ORGANIZATIONS_CURRENT_PAGE = 'SET_ORGANIZATIONS_CURRENT_PAGE';
export const SET_ORGANIZATIONS_ORDER = 'SET_ORGANIZATIONS_ORDER';
export const SET_ORGANIZATIONS_SEARCH_TERM = 'SET_ORGANIZATIONS_SEARCH_TERM';
export const SET_IS_ORGANIZATIONS_MODAL_OPEN = 'SET_IS_ORGANIZATIONS_MODAL_OPEN';
export const ADD_ORGANIZATION_TO_STORE = 'ADD_ORGANIZATION_TO_STORE';
export const UPDATE_ORGANIZATION_IN_STORE = 'UPDATE_ORGANIZATION_IN_STORE';
export const REMOVE_ORGANIZATION_FROM_STORE = 'REMOVE_ORGANIZATION_FROM_STORE';

export const SET_IS_CONTACTS_MODAL_OPEN = 'SET_IS_CONTACTS_MODAL_OPEN';
export const LIST_CONTACTS = 'LIST_CONTACTS';
export const SET_CONTACTS_ERROR = 'SET_CONTACTS_ERROR';
export const SET_CONTACTS_IS_FETCHING = 'SET_CONTACTS_IS_FETCHING';
export const SET_CONTACTS_IS_OUTDATED = 'SET_CONTACTS_IS_OUTDATED';

export const SAVE_GOALS = 'SAVE_GOALS';
export const RESET_GOALS = 'RESET_GOALS';

export const SAVE_PREVIOUS_SURVEY_ID = 'SAVE_PREVIOUS_SURVEY_ID';
export const SAVE_PREVIOUS_SURVEY_NETWORK = 'SAVE_PREVIOUS_SURVEY_NETWORK';
export const SET_RESUBMISSION = 'SET_RESUBMISSION';
export const CLEAR_RETAKE = 'CLEAR_RETAKE';

export const SAVE_USER_GOALS = 'SAVE_USER_GOALS';

export const SAVE_RETAKE_ANSWER = 'SAVE_RETAKE_ANSWER';
export const CLEAR_STATUS = 'CLEAR_STATUS';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SAVE_CONSENT = 'SAVE_CONSENT';
