import {
  ADD_PERSON,
  REMOVE_PERSON,
  UPDATE_PERSON,
  CLEAR_STATUS,
  UPDATE_ALL_PERSON,
} from '../actions/actionTypes';

const allIds = (state = [], action) => {
  switch (action.type) {
    case ADD_PERSON:
    case UPDATE_ALL_PERSON:
      if (state.indexOf(action.id) === -1) {
        return [...state, action.id];
      }
      return state;
    case REMOVE_PERSON:
      return state.filter((id) => id !== action.id);
    case UPDATE_PERSON:
      if (state.indexOf(action.id) === -1) {
        return [...state, action.id];
      }
      return state;
    case CLEAR_STATUS:
      return [];
    default:
      return state;
  }
};

export default allIds;
