import { useRouter } from 'next/navigation';

const withSessionHandler = (arg) => {
  const router = useRouter();
  try {
    let error;
    if (arg instanceof Array) {
      ({ error } = arg[1]);
    } else {
      ({ error } = arg);
    }
    if (error?.networkError?.result?.error === 'not_authenticated') {
      router.reload();
    }
    return arg;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default withSessionHandler;
