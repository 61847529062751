import React from 'react';
import { Row, Col } from 'reactstrap';
import { ContentIcon } from 'components/landings';
import PartnersAirplaneImg from 'public/assets/Partners-Airplane.svg';
import PartnersRocketIcon from 'public/assets/Partners-Rocket.svg';
import PartnersLadderIcon from 'public/assets/Partners-Ladder.svg';
import PartnersScalesIcon from 'public/assets/Partners-Scales.svg';
import ContactUs from './ContactUs';

export default function KeyTalentSection() {
  return (
    <>
      <Row
        id="key-talent"
        className="max_desktop_width_80 d-flex justify-content-center pb-5"
      >
        <Col className="key_talent_img d-flex flex-column justify-content-center col-12 col-lg-4" />
        <Col
          style={{ backgroundColor: 'rgba(245, 146, 34, .5)' }}
          className="d-flex justify-content-center col-12 col-lg-8 px-4 py-3"
        >
          <div className="p-5 pe-lg-7">
            <h2 className="text-start fw-bold pb-4">Key Talent Retention</h2>
            <h4 className="pb-4">
              Invest in & monitor the health of key talent networks
            </h4>
            <p>
              Inefficient networks can
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://journals.sagepub.com/doi/abs/10.1177/1052562915624124"
              >
                derail a manager’s path to success
              </a>
              {' '}
              , disrupt high performers, slow-down technical talent’s
              productivity, and
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hbr.org/2018/06/to-retain-new-hires-make-sure-you-meet-with-them-in-their-first-week"
              >
                impact
              </a>
              {' '}
              overall integration and desire to stay in organizations. Networks
              are critical for your key talent. Individuals with effective
              networks are able to take on leadership roles more quickly, adapt
              more easily and effectively, and become more influential change
              leaders.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="justify-content-center col-12 col-lg-5">
          <p className="landing_paragraph_normal pb-4">
            The LND helps HR leaders and program sponsors monitor the health of
            their key talent networks, so that they can quickly identify any
            issues and develop focused, measurable programs and solutions.
          </p>
          <img className="pb-4" src={PartnersAirplaneImg.src} />
          <p className="landing_paragraph_normal">
            Additionally, the LND provides key talent with awareness and agency
            over their own network effectiveness.
          </p>
        </Col>
        <Col className="col-lg-1" />
        <Col className="justify-content-center col-12 col-lg-5">
          <p className="landing_paragraph_normal pb-4">
            Learning this key information improves the likelihood that key
            talent will be retained or promoted by an organization.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sciencedirect.com/science/article/pii/S0049089X06000767?casa_token=HnGolu-W5vUAAAAA:z_Ppkat906XP_b9mj2CJxTITDSxEs16AWMAgkk0pssd2x5b71rs3DyIDhuSxceTJth4QsvVrPrBm"
            >
              Talent who spend time learning about their networks network are
            </a>
            :
          </p>
          <Row className="max_desktop_width_80 d-flex justify-content-center pb-5">
            <Col className="col-4 talent_content_icon">
              <ContentIcon
                icon={PartnersRocketIcon}
                subtext={(
                  <p
                    style={{ maxWidth: '12rem', height: '4rem' }}
                    className="text-center"
                  >
                    <strong style={{ fontSize: '2rem' }}>35%</strong>
                    {' '}
                    <br />
                    {' '}
                    more likely to receive top performance evaluations
                  </p>
                )}
              />
            </Col>
            <Col className="col-4 talent_content_icon">
              <ContentIcon
                icon={PartnersLadderIcon}
                subtext={(
                  <p
                    style={{ maxWidth: '12rem', height: '4rem' }}
                    className="text-center"
                  >
                    <strong style={{ fontSize: '2rem' }}>43%</strong>
                    {' '}
                    <br />
                    {' '}
                    more likely to receive a promotion
                  </p>
                )}
              />
            </Col>
            <Col className="col-4 talent_content_icon">
              <ContentIcon
                icon={PartnersScalesIcon}
                subtext={(
                  <p
                    style={{ maxWidth: '12rem', height: '4rem' }}
                    className="text-center"
                  >
                    <strong style={{ fontSize: '2rem' }}>42%</strong>
                    {' '}
                    <br />
                    {' '}
                    more likely to be retained by an organization
                  </p>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="max_desktop_width_80 d-flex justify-content-center pb-4">
        <Col className="d-flex align-items-center justify-content-center col-10 pb-4">
          <p
            style={{ color: 'rgb(245, 146, 34)', fontSize: '2rem' }}
            className="text-center"
          >
            Our suite of tools provides HR leaders with indicators to measure,
            maintain, or improve the “health” of key talent networks.
          </p>
        </Col>
      </Row>
      <ContactUs
        text="Contact us about improving your key talent networks"
        color="lndOrange"
      />
    </>
  );
}
