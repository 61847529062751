import React from 'react';
import { Row, Col } from 'reactstrap';
import ContactUs from './ContactUs';

export default function InnovationSection() {
  return (
    <>
      <Row
        id="innovation"
        className="max_desktop_width_80 d-flex justify-content-center pb-5 pt-5"
      >
        <Col className="innovation_img d-flex flex-column justify-content-center col-12 col-lg-5" />
        <Col
          style={{ backgroundColor: 'rgba(245, 146, 34, .5)' }}
          className="d-flex justify-content-center col-12 col-lg-7"
        >
          <div className="p-5 pe-lg-7">
            <h2 className="text-start fw-bold pb-4">Change & Innovation</h2>
            <h4 className="pb-4">Activate your change and innovation agents</h4>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ccl.org/articles/white-papers/analytics-for-change-how-networks-and-data-science-will-revolutionize-organizational-change"
              >
                Networks help accelerate change and innovation initiatives
              </a>
              , increase their overall adoption, and ensure intended outcomes
              are achieved. The personal networks of change and innovation
              agents are often the key to an initiative’s success.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="justify-content-center col-12 col-lg-4 pb-4 pb-lg-0">
          <h3
            className="text-center pb-5"
            style={{
              maxWidth: '25rem',
              margin: 'auto',
              color: 'rgb(245, 146, 34)',
            }}
          >
            With the LND, change & innovation agents get the tools they need to
            boost their personal networks to ensure project adoption.
          </h3>
        </Col>
        <Col className="col-lg-1" />
        <Col className="justify-content-center col-12 col-lg-6">
          <p className="landing_paragraph_normal pb-4">
            The LND helps innovation and change agents identify network gaps
            needed to improve project success. It provides quantifiable metrics
            to measure the effectiveness of the personal networks of change and
            innovation agents both prior to and during these initiatives. These
            measurements enable change and innovation leaders to quickly remedy
            gaps or provide new pathways to ensure project adoption.
          </p>
        </Col>
      </Row>
      <ContactUs
        text="Learn why better networks means more innovation"
        color="lndOrange"
      />
    </>
  );
}
