import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import * as constants from 'lib/constants';
import { contactUsIndividualLink } from 'lib/constants';
import LandingButton from '../../LandingButton';

export default function ContactUs({ text, color = 'lndOrange' }) {
  return (
    <>
      <Row
        className="contact_us max_desktop_width_60 d-flex justify-content-center p-2"
        style={{ border: `1px solid ${constants[color]}`, maxWidth: '50rem' }}
      >
        <Col className="col-12 col-md-8 d-flex flex-column align-items-center justify-content-center pb-4 pb-md-0 pb-lg-0">
          <p style={{ margin: '0' }}>{text}</p>
        </Col>
        <Col className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-center">
          <LandingButton color={color} url={contactUsIndividualLink} text="Contact Us" newTab />
        </Col>
      </Row>
      {' '}
    </>
  );
}

ContactUs.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ContactUs.defaultProps = {
  color: 'lndOrange',
};
