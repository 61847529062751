import {
  ADD_PERSON,
  UPDATE_PERSON,
  REMOVE_PERSON,
  ADD_CONNECTION,
  REMOVE_CONNECTION,
  UPDATE_ALL_PERSON,
  CLEAR_STATUS,
  PRUNE_CONNECTION,
} from '../actions/actionTypes';

const person = (state = {}, action) => {
  const { type, ...props } = action;

  switch (action.type) {
    case ADD_PERSON:
    case UPDATE_ALL_PERSON:
      return {
        ...props,
        network: props.network || [],
      };
    case UPDATE_PERSON:
      if (!state.id) {
        return {
          id: action.id,
          network: [], // TODO: DELETE I think this should be removed
          [action.field]: action.value,
        };
      }
      if (state.id === action.id) {
        return {
          ...state,
          [action.field]: action.value,
        };
      }
      return state;

    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  const current = state[action.id];
  const other = state[action.otherPerson];

  switch (action.type) {
    case ADD_PERSON:
    case UPDATE_ALL_PERSON:
    case UPDATE_PERSON:
      return {
        ...state,
        [action.id]: person(state[action.id], action),
      };
    case REMOVE_PERSON:
      return Object.keys(state)
        .filter((id) => id !== action.id)
        .reduce((newState, id) => {
          newState[id] = state[id];
          return newState;
        }, {});
    case ADD_CONNECTION:
      return {
        ...state,
        [action.id]: {
          ...current,
          network: [...current.network, action.otherPerson],
        },
        [action.otherPerson]: {
          ...other,
          network: [...other.network, action.id],
        },
      };
    case REMOVE_CONNECTION:
      return {
        ...state,
        [action.id]: {
          ...current,
          network: current.network.filter((id) => id !== action.otherPerson),
        },
        [action.otherPerson]: {
          ...other,
          network: other.network.filter((id) => id !== action.id),
        },
      };
    case PRUNE_CONNECTION:
      return {
        ...state,
        [action.id]: {
          ...current,
          network: current.network.filter((id) => id !== action.otherPerson),
        },
      };
    case CLEAR_STATUS:
      return {};
    default:
      return state;
  }
};

export default byId;
