import { LOAD } from 'redux-storage';
import {
  LIST_COHORTS,
  SELECT_COHORT,
  ADD_COHORT_TO_STORE,
  UPDATE_COHORT_IN_STORE,
  REMOVE_COHORT_FROM_STORE,
  SET_SURVEY_COHORT,
  REMOVE_SURVEY_COHORT,
  SET_COHORT_COUNT,
  SET_COHORTS_CURRENT_PAGE,
  SET_COHORTS_ORDER,
  SET_COHORTS_SEARCH_TERM,
  SET_IS_COHORTS_MODAL_OPEN,
  SET_COHORTS_ERROR,
  SELECT_ORGANIZATION,
} from '../actions/actionTypes';

const defaultState = {
  cohortsList: [],
  cohortCount: 0,
  selectedCohort: {},
  surveyCohort: {},
  currentPage: 1,
  order: {
    field: 'created',
    secondaryField: 'name',
    direction: -1,
  },
  searchTerm: '',
  isFetching: true,
  isModalOpen: false,
  isUpdateModal: false,
  isCohortsListOutdated: true,
  error: {
    isError: false,
    errorMsg: '',
  },
};

const cohorts = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        currentPage: 1,
        order: {
          field: 'created',
          secondaryField: 'name',
          direction: -1,
        },
        searchTerm: '',
        error: { isError: false, errorMsg: '' },
      };
    case LIST_COHORTS:
      return {
        ...state,
        cohortsList: action.cohorts,
        isFetching: false,
        isCohortsListOutdated: false,
      };
    case SELECT_COHORT:
      return { ...state, selectedCohort: action.cohort };
    case ADD_COHORT_TO_STORE:
      return {
        ...state,
        isModalOpen: false,
        isUpdateModal: false,
        isFetching: true,
        isCohortsListOutdated: true,
      };
    case UPDATE_COHORT_IN_STORE: {
      return {
        ...state,
        cohortsList: state.cohortsList.map((cohort) => {
          if (cohort.id !== action.cohort.id) {
            return cohort;
          }
          return {
            ...cohort,
            ...action.cohort,
          };
        }),
        isModalOpen: false,
        isUpdateModal: false,
      };
    }
    case REMOVE_COHORT_FROM_STORE: {
      return {
        ...state,
        selectedCohort: {},
        isModalOpen: false,
        isUpdateModal: false,
        isFetching: true,
        isCohortsListOutdated: true,
      };
    }
    case SET_SURVEY_COHORT: {
      return {
        ...state,
        surveyCohort: { ...state.surveyCohort, ...action.cohort },
      };
    }
    case REMOVE_SURVEY_COHORT: {
      return {
        ...state,
        surveyCohort: {},
      };
    }
    case SET_COHORT_COUNT: {
      return {
        ...state,
        cohortCount: action.cohortCount,
      };
    }
    case SET_COHORTS_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
        isFetching: true,
        isCohortsListOutdated: true,
      };
    }
    case SET_COHORTS_ORDER: {
      return {
        ...state,
        order: { ...state.order, ...action.order },
        currentPage: 1,
        isFetching: true,
        isCohortsListOutdated: true,
      };
    }
    case SET_COHORTS_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        currentPage: 1,
        isFetching: true,
        isCohortsListOutdated: true,
      };
    }
    case SET_IS_COHORTS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: action.isModalOpen,
        isUpdateModal: action.isUpdateModal,
      };
    }
    case SET_COHORTS_ERROR: {
      return {
        ...state,
        error: { isError: action.isError, errorMsg: action.errorMsg },
        isFetching: false,
        isCohortsListOutdated: false,
      };
    }
    case SELECT_ORGANIZATION: {
      return {
        ...state,
        isCohortsListOutdated: true,
      };
    }

    default:
      return state;
  }
};

export default cohorts;
