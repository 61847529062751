import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { webURL } from 'lib/utils/parser';

function WebLink({ href, children, ...props }) {
  const ref = useRef();
  const newHref = webURL(href);

  useEffect(() => {
    const windowRef = webURL(href);
    if (ref.current.href !== windowRef) {
      ref.current.href = windowRef;
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a {...props} ref={ref} href={newHref} target="_blank" rel="noreferrer">
      {children?.type === 'a' ? children.props.children : children}
    </a>
  );
}

WebLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};

WebLink.defaultProps = {
  children: null,
};

export default WebLink;
