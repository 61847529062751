import { LOAD } from 'redux-storage';
import {
  LIST_ORGANIZATIONS,
  SET_ORGANIZATIONS_COUNT,
  SET_ORGANIZATIONS_ERROR,
  SELECT_ORGANIZATION,
  SET_ORGANIZATIONS_CURRENT_PAGE,
  SET_ORGANIZATIONS_ORDER,
  SET_ORGANIZATIONS_SEARCH_TERM,
  SET_IS_ORGANIZATIONS_MODAL_OPEN,
  ADD_ORGANIZATION_TO_STORE,
  UPDATE_ORGANIZATION_IN_STORE,
  REMOVE_ORGANIZATION_FROM_STORE,
} from '../../actions/actionTypes';

const defaultState = {
  organizationsList: [],
  organizationsCount: 0,
  selectedOrganization: {},
  currentPage: 1,
  order: {
    field: 'created',
    secondaryField: 'name',
    direction: -1,
  },
  searchTerm: '',
  isFetching: true,
  isModalOpen: false,
  isUpdateModal: false,
  isOrganizationsListOutdated: true,
  error: {
    isError: false,
    errorMsg: '',
  },
};

const organizations = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        currentPage: 1,
        order: {
          field: 'created',
          secondaryField: 'name',
          direction: -1,
        },
        searchTerm: '',
        error: { isError: false, errorMsg: '' },
      };
    case LIST_ORGANIZATIONS:
      return {
        ...state,
        organizationsList: action.organizations,
        isFetching: false,
        isOrganizationsListOutdated: false,
      };
    case SELECT_ORGANIZATION:
      return { ...state, selectedOrganization: action.organization };
    case SET_ORGANIZATIONS_COUNT: {
      return {
        ...state,
        organizationsCount: action.organizationsCount,
      };
    }
    case SET_ORGANIZATIONS_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
        isFetching: true,
        isOrganizationsListOutdated: true,
      };
    }
    case SET_ORGANIZATIONS_ORDER: {
      return {
        ...state,
        order: { ...state.order, ...action.order },
        currentPage: 1,
        isFetching: true,
        isOrganizationsListOutdated: true,
      };
    }
    case SET_ORGANIZATIONS_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        currentPage: 1,
        isFetching: true,
        isOrganizationsListOutdated: true,
      };
    }
    case SET_IS_ORGANIZATIONS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: action.isModalOpen,
        isUpdateModal: action.isUpdateModal,
      };
    }
    case SET_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        error: { isError: action.isError, errorMsg: action.errorMsg },
        isFetching: false,
        isOrganizationsListOutdated: false,
      };
    }
    case ADD_ORGANIZATION_TO_STORE:
      return {
        ...state,
        isModalOpen: false,
        isUpdateModal: false,
        isFetching: true,
        isOrganizationsListOutdated: true,
      };
    case UPDATE_ORGANIZATION_IN_STORE: {
      return {
        ...state,
        organizationsList: state.organizationsList.map((organization) => {
          if (organization.id !== action.organization.id) {
            return organization;
          }
          return {
            ...organization,
            ...action.organization,
          };
        }),
        isModalOpen: false,
        isUpdateModal: false,
        isFetching: true,
        isOrganizationsListOutdated: true,
      };
    }
    case REMOVE_ORGANIZATION_FROM_STORE: {
      return {
        ...state,
        selectedOrganization: {},
        isModalOpen: false,
        isUpdateModal: false,
        isFetching: true,
        isOrganizationsListOutdated: true,
      };
    }

    default:
      return state;
  }
};

export default organizations;
