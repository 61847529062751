import React from 'react';
import { Container, Row } from 'reactstrap';
import changes from 'public/assets/LND-website-network-changes.svg';
import effectiveness from 'public/assets/LND-website-network-effectiveness.svg';
import success from 'public/assets/LND-website-network-success.svg';
import MiddleSectionItem from './MiddleSectionItem';

const itemsContent = [
  {
    itemText: (
      <p>
        Assess the current effectiveness
        {' '}
        <br />
        {' '}
        of your network
      </p>
    ),
    itemImage: effectiveness,
  },
  {
    itemText: (
      <p>
        Make small changes that have a big
        {' '}
        <br />
        {' '}
        impact on your network
      </p>
    ),
    itemImage: changes,
  },
  {
    itemText: (
      <p>
        Discover how your professional
        {' '}
        <br />
        {' '}
        network can help you succeed
      </p>
    ),
    itemImage: success,
  },
];

const renderMiddleItems = () => itemsContent.map((content) => (
  <MiddleSectionItem
    key={content.itemImage}
    itemText={content.itemText}
    itemImage={content.itemImage}
  />
));

export default function LandingMiddleSection() {
  return (
    <Container fluid className="middle_section px-0">
      <Row className="w-100 middle_row max_desktop_width_60 pt-6 pt-lg-0 pb-3 pb-lg-0">
        {renderMiddleItems()}
      </Row>
    </Container>
  );
}
