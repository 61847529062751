import AboutPageLayout from './AboutPageLayout';
import CoursesPageLayout from './CoursesPageLayout';
import DiagnosticPageLayout from './DiagnosticPageLayout';
import HomePageLayout from './HomePageLayout';
import PartnersPageLayout from './PartnersPageLayout';
import ResearchPageLayout from './ResearchPageLayout';
import TrainerPageLayout from './TrainerPageLayout';
import TrainerCoursePageLayout from './TrainerCoursePageLayout';

import ContentIcon from './ContentIcon';
import Hero from './Hero';
import ModalVideo from './ModalVideo';
import PreviewImage from './PreviewImage';
import Video from './Video';

export {
  AboutPageLayout,
  ContentIcon,
  CoursesPageLayout,
  DiagnosticPageLayout,
  Hero,
  HomePageLayout,
  ModalVideo,
  PartnersPageLayout,
  PreviewImage,
  ResearchPageLayout,
  TrainerPageLayout,
  TrainerCoursePageLayout,
  Video,
};
