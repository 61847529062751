import { combineReducers } from 'redux';
import user from 'lib/redux/reducers/users';
import { allSurveyIds, surveysById, totalCount } from 'lib/redux/reducers/surveys';
import additional from 'lib/redux/reducers/additional';
import reduceReducers from 'reduce-reducers';
import allIds from 'lib/redux/reducers/allIds';
import byId from 'lib/redux/reducers/byId';
import cohortCode from 'lib/redux/reducers/cohortCode';
import results from 'lib/redux/reducers/results';
import stakeholders from 'lib/redux/reducers/stakeholders';
import updateGraph from 'lib/redux/reducers/updateGraph';
import you from 'lib/redux/reducers/you';
import activeSurveyID from 'lib/redux/reducers/activeSurveyID';
import cohortStatistics from 'lib/redux/reducers/cohortStatistics';
import cohorts from 'lib/redux/reducers/cohorts';
import isSavingSurvey from 'lib/redux/reducers/isSavingSurvey';
import organizations from 'lib/redux/reducers/organizations/organizations';
import contacts from 'lib/redux/reducers/contacts';
import goals from 'lib/redux/reducers/goals';
import retake from 'lib/redux/reducers/retake';
import userGoals from 'lib/redux/reducers/userGoals';
import retakeAnswers from 'lib/redux/reducers/retakeAnswers';
import modal from 'lib/redux/reducers/modal';
import consent from 'lib/redux/reducers/consent';

const rootReducer = reduceReducers(
  combineReducers({
    you,
    stakeholders,
    additional,
    byId,
    allIds,
    results,
    cohortCode,
    activeSurveyID,
    allSurveyIds,
    totalCount,
    surveysById,
    cohortStatistics,
    cohorts,
    user,
    isSavingSurvey,
    organizations,
    contacts,
    goals,
    retake,
    userGoals,
    retakeAnswers,
    modal,
    consent,
  }),
  // This is an anti-pattern. This allows us to access root State, but we should probably change
  // state shape so that is unnecessary.
  // WARNING: You cannot add new keys to the root state that are not added by combineReducers()
  // above or they will be pruned!
  // You can however modify the state managed by the slice reducers
  updateGraph
);

export default rootReducer;
