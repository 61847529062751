import { Container } from 'reactstrap';
import React from 'react';
import Hero from './Hero';
import Diagnostic from './Diagnostic';

function LandingInitialSection() {
  return (
    <Container fluid className="initial_section px-0 pt-4">
      <Hero />
      <Diagnostic />
    </Container>
  );
}
export default LandingInitialSection;
