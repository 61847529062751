import { gql, useQuery } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const SAVE_RETAKE_ANSWER = gql`
  mutation addRetakeAnswer(
    $retakeAnswers: RetakeAnswerInput
    $user: UserInput
    $cohort_id: ID
    $survey_id: ID
  ) {
    addRetakeAnswer(
      retakeAnswers: $retakeAnswers
      user: $user
      cohort_id: $cohort_id
      survey_id: $survey_id
    )
  }
`;

const GET_RETAKE_ANSWER = gql`
  query getRetakeAnswer($surveyId: String!) {
    getRetakeAnswer(surveyId: $surveyId) {
      retakeAnswers {
        action
        barriers
        advices
      }
      survey_id
      cohort_id
      _id
    }
  }
`;

const useRetakeAnswer = () => {
  const [saveRetakeAnswer] = withSessionHandler(useMutation(SAVE_RETAKE_ANSWER));

  const getRetakeAnswers = ({ surveyId }) => {
    const doc = withSessionHandler(
      useQuery(GET_RETAKE_ANSWER, {
        variables: {
          surveyId,
        },
        fetchPolicy: 'no-cache',
        skip: !surveyId,
      })
    );
    return { loading: doc.loading, result: doc.data, errors: doc.error };
  };

  return {
    saveRetakeAnswer,
    getRetakeAnswers,
  };
};

export default useRetakeAnswer;
