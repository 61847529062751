import { RESET_GOALS, SAVE_GOALS } from '../actions/actionTypes';

const defaultState = {
  id: '',
  challenges: '',
  goals_self: '',
  goals_team: '',
  feedback: '',
  focus: {
    key: [],
    areas: [],
    description: '',
    extra: '',
  },
  actions: [],
  lastSurvey: null,
};

const goals = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_GOALS:
      return {
        ...state,
        ...action.goals,
      };
    case RESET_GOALS:
      return defaultState;
    default:
      return state;
  }
};

export default goals;
