import { LOAD } from 'redux-storage';
import {
  SET_IS_CONTACTS_MODAL_OPEN,
  LIST_CONTACTS,
  SET_CONTACTS_ERROR,
  SET_CONTACTS_IS_FETCHING,
  SET_CONTACTS_IS_OUTDATED,
  SELECT_COHORT,
} from '../actions/actionTypes';

const defaultState = {
  contactsList: [],
  isFetching: true,
  isModalOpen: false,
  isContactsListOutdated: true,
  error: {
    isError: false,
    errorMsg: '',
  },
};

const contacts = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: { isError: false, errorMsg: '' },
      };
    case SET_IS_CONTACTS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
        isUpdateModal: action.isUpdateModal,
      };
    case LIST_CONTACTS:
      return {
        ...state,
        contactsList: action.contacts,
        isFetching: false,
        isContactsListOutdated: false,
      };
    case SET_CONTACTS_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SET_CONTACTS_IS_OUTDATED:
      return {
        ...state,
        isContactsListOutdated: action.isContactsListOutdated,
      };
    case SELECT_COHORT:
      return {
        ...state,
        isContactsListOutdated: true,
        contactsList: [],
      };
    case SET_CONTACTS_ERROR: {
      return {
        ...state,
        error: { isError: action.isError, errorMsg: action.errorMsg },
        isFetching: false,
        isContactsListOutdated: true,
      };
    }
    default:
      return state;
  }
};

export default contacts;
