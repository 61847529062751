import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { NavItem, NavLink } from 'reactstrap';
import { contactUsIndividualLink } from 'lib/constants';

const links = [
  { href: '/', text: 'Account', internal: true },
  { href: contactUsIndividualLink, text: 'Contact', internal: false },
];

const BurgerMenu = ({ onClick }) => {
  const router = useRouter();

  return (
    <>
      {links.map((link) => {
        return (
          <NavItem key={link.href}>
            <div onClick={onClick} className="header_link">
              {!link.internal ? (
                <NavLink
                  active={false}
                  style={{ color: 'rgba(0, 0, 0, 0.5)' }}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.text}
                </NavLink>
              ) : (
                <Link href={link.href} passHref legacyBehavior>
                  <NavLink active={router.pathname === link.href}>{link.text}</NavLink>
                </Link>
              )}
            </div>
          </NavItem>
        );
      })}
    </>
  );
};

export default BurgerMenu;
