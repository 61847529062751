import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_GOALS_STATISTICS_BY_COHORT = gql`
  query goalsStatisticsByCohort($cohortId: String!) {
    goalsStatisticsByCohort(cohortId: $cohortId) {
      openness_closed
      openness_open
      openness_too_open
      diversity_focused
      diversity_diverse
      depth_distant
      depth_balanced
      depth_deep
      resources_perform
      resources_prosper
      resources_progress
      resources_prepare
      other_toxic
      other_small
      other_overwhelming
      other_not_listed
      resources_assistance
      resources_energy
      resources_sponsorship
      resources_innovation
      resources_support
      resources_friendship
      resources_development
      resources_purpose
      diversity_demographic
      diversity_geographic
      diversity_horizontal
      diversity_vertical
      diversity_stakeholder
    }
  }
`;

const useGoalsStatistics = () => {
  const getGoalStatisticsByCohort = (cohortId) => {
    const doc = withSessionHandler(
      useQuery(GET_GOALS_STATISTICS_BY_COHORT, {
        variables: {
          cohortId,
        },
      })
    );

    return {
      loading: doc.loading,
      goalStatistics: doc.data?.goalsStatisticsByCohort,
    };
  };

  return { getGoalStatisticsByCohort };
};

export default useGoalsStatistics;
