import { SET_IS_SAVING_SURVEY } from '../actions/actionTypes';

export default (state = false, action) => {
  switch (action.type) {
    case SET_IS_SAVING_SURVEY:
      return action.isSavingSurvey;
    default:
      return state;
  }
};
