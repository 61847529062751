import ReactPlayer from 'react-player';

function Video({ videoUrl }) {
  return (
    <div id="landing_player" className="react-player-wrapper landing_player">
      <ReactPlayer
        url={videoUrl}
        className="react-player"
        width="100%"
        height="100%"
        playing
        controls
      />
    </div>
  );
}

export default Video;
