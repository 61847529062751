import { gql, useMutation } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_SURVEY_BY_EMAIL = gql`
  query getSurveyByEmail($email: String!) {
    getSurveyByEmail(email: $email) {
      surveys {
        survey {
          id
          created
        }
        cohort {
          id
          name
        }
      }
    }
  }
`;

const GET_SURVEY = gql`
  query getSurvey($id: String!) {
    getSurvey(id: $id) {
      id
      created
      previousSurveyId
      completed
      cohort_id
      submitted
      survey {
        you {
          id
          name
          network
          email
          leader
          gender
          age
          industry
          department
          employeeCount
        }
        network {
          id
          name
          network
          where
          company
          position
          leader
          gender
          age
          sameEthnicity
          years
          relationship
          work
          career
          support
          growth
          mentoring
          friend
          motivators
          innovators
        }
        version
      }
    }
  }
`;

export const ANONYMIZE_SURVEY = gql`
  mutation anonymizeSurveys($surveys: [String]) {
    anonymizeSurveys(surveys: $surveys)
  }
`;

const CHANGE_SURVEY_COHORT = gql`
  mutation changeSurveyCohort($surveyId: ID, $cohortId: ID) {
    changeSurveyCohort(surveyId: $surveyId, cohortId: $cohortId)
  }
`;

const useSurvey = () => {
  const getSurvey = ({ id }) => {
    const doc = withSessionHandler(
      useQuery(GET_SURVEY, {
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        skip: !id,
      })
    );
    return { loading: doc.loading, result: doc.data, errors: doc.error };
  };

  const getSurveysAndCohortByEmail = ({ email }) => {
    const doc = withSessionHandler(
      useQuery(GET_SURVEY_BY_EMAIL, {
        variables: {
          email,
        },
        fetchPolicy: 'no-cache',
        skip: !email,
      })
    );
    return {
      loading: doc.loading,
      result: doc.data,
      errors: doc.error,
      refetch: doc.refetch,
    };
  };

  const [anonymizeSurveys] = withSessionHandler(useMutation(ANONYMIZE_SURVEY));

  const [changeSurveyCohort] = withSessionHandler(useMutation(CHANGE_SURVEY_COHORT));
  
   return { getSurvey, getSurveysAndCohortByEmail, anonymizeSurveys, changeSurveyCohort };

};

export { useSurvey, GET_SURVEY };
