import {
  ADD_SURVEYS_FOR_TRAINER,
  SET_SURVEY_COUNT,
} from '../actions/actionTypes';

const totalCount = (state = 0, action) => {
  switch (action.type) {
    case SET_SURVEY_COUNT:
      return action.totalCount;
    default:
      return state;
  }
};

const allSurveyIds = (state = [], action) => {
  switch (action.type) {
    case ADD_SURVEYS_FOR_TRAINER:
      return action.surveys.map((survey) => survey.id);
    default:
      return state;
  }
};

const surveysById = (state = {}, action) => {
  switch (action.type) {
    case ADD_SURVEYS_FOR_TRAINER:
      return action.surveys.reduce((accum, survey) => Object
        .assign(accum, { [survey.id]: survey }), {});
    default:
      return state;
  }
};

export { allSurveyIds, surveysById, totalCount };
