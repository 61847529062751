import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setOrganizationsList,
  setOrganizationsCount,
  setOrganizationsError,
} from 'lib/redux/actions';
import {
  getOrganizationsCurrentPage,
  getOrganizationsOrder,
  getOrganizationsSearchTerm,
  getIsOrganizationsListOutdated,
} from 'lib/redux/selectors';
import { ROLES } from 'lib/utils/constants';

const GET_ORGANIZATONS = gql`
  query organizationsList(
    $skip: Int
    $limit: Int
    $orderField: String
    $secondaryOrderField: String
    $orderDirection: Int
    $searchTerm: String
    $email: String
  ) {
    organizations(
      skip: $skip
      limit: $limit
      orderField: $orderField
      secondaryOrderField: $secondaryOrderField
      orderDirection: $orderDirection
      searchTerm: $searchTerm
      email: $email
    ) {
      organizations {
        id
        name
        availableLicenses
        orgRepresentatives
        unlimitedSurveyLicenses
        surveyLicensePrice
        trainerLicensePrice
        cohortsCount
        createdAt
        updatedAt
        demographicQuestions
        retentionPolicy
        retentionDays
      }
      totalCount
    }
  }
`;

export default function useOranizationsList(role = '', email = '') {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const isAdmin = role === ROLES.ADMIN;
  const isOrgRep = role === ROLES.ORG_REP;
  const currentPage = useSelector(getOrganizationsCurrentPage);
  const order = useSelector(getOrganizationsOrder);
  const isOutdated = useSelector(getIsOrganizationsListOutdated);
  const searchTerm = useSelector(getOrganizationsSearchTerm);
  const PER_PAGE = 10;

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        if (!isOutdated) return;

        if (!isAdmin && !isOrgRep) return;

        const variables = {
          skip: (currentPage - 1) * PER_PAGE,
          limit: PER_PAGE,
          orderField: order.field,
          secondaryOrderField: order.secondaryField,
          orderDirection: order.direction,
          searchTerm,
        };
        const {
          data: {
            organizations: { organizations, totalCount },
          },
        } = await client.query({
          query: GET_ORGANIZATONS,
          variables: isAdmin ? variables : { ...variables, email },
          fetchPolicy: 'no-cache',
        });
        dispatch(setOrganizationsCount(totalCount));
        dispatch(setOrganizationsList(organizations));
      } catch (err) {
        dispatch(
          setOrganizationsError(
            true,
            'An error occurred while fetching the organizations.'
          )
        );
        Sentry.captureException(err);
      }
    };

    getOrganizations();
  }, [role, isOutdated]);
}
