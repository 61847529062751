import { getPerson } from 'lib/redux/selectors';
import { ADD_PERSON, REMOVE_PERSON } from '../actions/actionTypes';

const updateGraph = (state, action) => {
  switch (action.type) {
    case ADD_PERSON:
      return {
        ...state,
        results: {
          ...state.results,
        },
        byId: Object.keys(state.byId).reduce((newById, key) => {
          const isYou = key === state.you;
          const person = getPerson(state, key);
          // Add the new person to the ego's network
          // and ego to new person's network
          if (isYou) {
            newById[key] = {
              ...person,
              network: [...person.network, action.id],
            };
          } else {
            const newNetwork =
              person.network.indexOf(state.you) === -1
                ? [...person.network, state.you]
                : person.network;

            newById[key] = {
              ...person,
              network: newNetwork,
            };
          }

          return newById;
        }, {}),
      };
    case REMOVE_PERSON:
      return {
        ...state,
        results: {
          ...state.results,
        },
        byId: Object.keys(state.byId).reduce((newById, key) => {
          const person = getPerson(state, key);
          // Remove person from all networks
          newById[key] = {
            ...person,
            network: person.network.filter((id) => id !== action.id),
          };
          return newById;
        }, {}),
      };
    default:
      return state;
  }
};

export default updateGraph;
