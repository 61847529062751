import React from 'react';
import { Row, Col } from 'reactstrap';
import PartnersDiversityHelpingIcon from 'public/assets/Partners-Diversity_Helping.svg';
import ContactUs from './ContactUs';

export default function BelongingSection() {
  return (
    <>
      <Row
        id="belonging"
        className="max_desktop_width_80 d-flex justify-content-center pb-5 pt-5"
      >
        <Col className="belonging_img d-flex flex-column justify-content-center col-12 col-lg-6" />
        <Col
          style={{ backgroundColor: 'rgba(27, 117, 186, .5)' }}
          className="d-flex justify-content-center col-12 col-lg-6"
        >
          <div className="p-5 pe-lg-6">
            <h2 className="text-start fw-bold pb-4">
              Belonging, Diversity, & Inclusion
            </h2>
            <h4 className="pb-4">Help sponsors diversify their networks</h4>
            <p>
              The LND helps organizations improve their belonging, diversity,
              and inclusion measurements and initiatives. It provides “sponsors”
              with direct evidence of the diversity of the talent they are
              sponsoring or mentoring.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="justify-content-center col-12 col-lg-5 pb-4 pb-lg-0">
          <h3
            className="text-center pb-5"
            style={{
              maxWidth: '25rem',
              margin: 'auto',
              color: 'rgb(27, 117, 186)',
            }}
          >
            The LND makes the critical connections required for more equitable
            workplaces visible and actionable.
          </h3>
          <p className="landing_paragraph_normal">
            Organizations can use this information to rectify under sponsorship
            or over mentorship of their diverse talent. Additionally, the LND
            provides diverse talent with actionable feedback for closing gaps in
            their network, specifically mentorship and sponsorship gaps
            identified that provide pathways to promotions and upward mobility.
          </p>
        </Col>
        <Col className="col-lg-1" />
        <Col className="justify-content-center col-12 col-lg-5">
          <p className="landing_paragraph_normal pb-4">
            <img
              src={PartnersDiversityHelpingIcon.src}
              style={{ maxWidth: '6rem', marginRight: '2rem' }}
              align="left"
              alt="Partners and Diversity"
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2010/09/why-men-still-get-more-promotions-than-women"
            >
              Women are often over mentored and under sponsored
              {' '}
            </a>
            in organizations. Racially diverse candidates
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2019/03/race-at-work"
            >
              often miss out
              {' '}
            </a>
            on key career experiences because of the lack of sponsorship,
            specifically key connections, in leadership networks. Because of
            this, women and racially diverse people are often underrepresented
            in organizational leadership positions, which impact the overall
            parity and justice in an organization. Equal access to sponsorship
            and leadership networks is a necessity if we are to ensure belonging
            and inclusion for all in today’s organization.
          </p>
        </Col>
      </Row>
      <ContactUs
        text="Learn how Network Leader fosters diverse networks"
        color="lndBlue"
      />
    </>
  );
}
