import {
  CLEAR_RETAKE,
  SAVE_PREVIOUS_SURVEY_ID,
  SAVE_PREVIOUS_SURVEY_NETWORK,
  SET_RESUBMISSION,
} from '../actions/actionTypes';

const defaultState = {
  previousSurveyId: null,
  resubmission: null,
  previousNetwork: null,
};

const retake = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_PREVIOUS_SURVEY_ID:
      return {
        ...state,
        previousSurveyId: action.id,
      };
    case SAVE_PREVIOUS_SURVEY_NETWORK:
      return {
        ...state,
        previousNetwork: action.network,
      };
    case SET_RESUBMISSION:
      return {
        ...state,
        resubmission: action.id,
      };
    case CLEAR_RETAKE:
      return {
        ...state,
        resubmission: null,
        previousNetwork: null,
      };
    default:
      return state;
  }
};

export default retake;
