import { SAVE_USER_GOALS } from '../actions/actionTypes';

const userGoals = (state = [], action) => {
  switch (action.type) {
    case SAVE_USER_GOALS:
      return [...state, ...action.goals];
    default:
      return state;
  }
};

export default userGoals;
