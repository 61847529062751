import React from 'react';
import { Row, Col } from 'reactstrap';
import PartnersTacksIcon from 'public/assets/Partners-Tacks.svg';
import PartnersLeadershipGraphIcon from 'public/assets/Partners-LeadershipGraph.svg';
import ContactUs from './ContactUs';

export default function LeadershipSection() {
  return (
    <>
      <Row
        id="leadership"
        className="max_desktop_width_80 d-flex justify-content-center pb-5 pt-5"
      >
        <Col className="leadership_img d-flex flex-column justify-content-center col-12 col-lg-4" />
        <Col
          style={{ backgroundColor: 'rgba(27, 117, 186, .5)' }}
          className="d-flex justify-content-center col-12 col-lg-8 px-4 py-3"
        >
          <div className="p-5 pe-lg-7">
            <h2 className="text-start fw-bold pb-4" style={{ maxWidth: '25rem' }}>
              Overall Leadership Development
            </h2>
            <p>
              Leaders with effective (e.g.,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://journals.sagepub.com/doi/10.1177/1046496409333404"
              >
                open
              </a>
              ) networks tend to have higher performing groups and teams. Moreover,
              leaders are most effective not only when they have built a network that
              positions them well, but also when they have an{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.111.1110&rep=rep1&type=pdf"
              >
                accurate understanding
              </a>{' '}
              of the network within their organization. This astute understanding of their
              network allows them to develop the connections they need and also help those
              they lead develop the networks they need to be effective.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="d-flex justify-content-center justify-content-lg-end align-items-lg-start col-12 col-lg-2 pb-4 pb-lg-0 px-0">
          <img
            src={PartnersLeadershipGraphIcon.src}
            className="float-lg-right"
            style={{ maxWidth: '6rem' }}
            align="left"
            alt="Partner Leadership Graph"
          />
        </Col>
        <Col className="justify-content-center col-12 col-lg-10 pb-4 pb-lg-0 px-0">
          <h3
            className="text-center pb-5 pt-4"
            style={{
              paddingLeft: '0.5rem',
              maxWidth: '55rem',
              color: 'rgb(27, 117, 186)',
            }}
          >
            Networks are a critical aspect of leadership development that is
            under-emphasized in most organizations.{' '}
            <sup>
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://psycnet.apa.org/record/2016-50675-001"
              >
                1
              </a>
            </sup>
          </h3>
        </Col>
      </Row>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-4">
        <Col className="justify-content-center col-12 col-lg-5 pb-4 pb-lg-0">
          <p className="landing_paragraph_normal">
            The LND can be used to integrate a network lens to your organization&apos;s
            leadership development programs and initiatives. It provides leaders with
            actionable metrics to improve their leadership connections and strategies to
            increase overall effectiveness. The LND also provides Talent Managers,
            Learning & Development leaders, and program sponsors with insights on the
            overall network health of your organization&apos;s leadership cohorts.
          </p>
        </Col>
        <Col className="col-lg-1" />
        <Col className="justify-content-center col-12 col-lg-5">
          <img className="pb-5" src={PartnersTacksIcon.src} alt="Partners Tacks" />
          <h3
            className="text-center pb-5"
            style={{
              maxWidth: '25rem',
              margin: 'auto',
              color: 'rgb(27, 117, 186)',
            }}
          >
            With the LND, your leaders can build better and more strategic networks
            elevating their individual and collective performance.
          </h3>
        </Col>
      </Row>
      <ContactUs
        text="Learn why better networks means more innovation "
        color="lndBlue"
      />
    </>
  );
}
