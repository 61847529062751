import React from 'react';

function BrowserNotSupported() {
  return (
    <h3 style={{ margin: '3rem' }}>
      Browser not supported. Please try a different web browser.
    </h3>
  );
}

export default BrowserNotSupported;
