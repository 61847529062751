import { UPDATE_RESULTS } from 'lib/redux/actions/actionTypes';
import computeDepth from 'lib/utils/computeDepth';
import computeDiversity from 'lib/utils/computeDiversity';

const initialState = {
  rawResult: null,
  depth: {
    data: {},
    label: null,
    percent: null,
  },
  diversity: {
    data: [],
    percent: null,
  },
  openness: null,
};

const results = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESULTS:
      // TODO: Audit the use of these fields and simplify
      return {
        ...state,
        rawResult: action.payload.responseBody,
        depth: computeDepth(action.payload.responseBody.deep),
        diversity: computeDiversity(action.payload.responseBody.boundaries),
        openness: action.payload.responseBody.open,
        resources: action.payload.responseBody.resources.individual,
      };
    default:
      return state;
  }
};

export default results;
