import { gql } from '@apollo/client';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import * as Sentry from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withSessionHandler from 'lib/utils/withSessionHandler';
import {
  setContactsList,
  setContactsError,
  setContactsIsFetching,
} from 'lib/redux/actions';
import { getIsContactsListIsOutdated, getSelectedCohort } from 'lib/redux/selectors';

const GET_CONTACTS = gql`
  query contactsList($cohortId: String, $order: Int, $orderField: String) {
    contacts(cohortId: $cohortId, order: $order, orderField: $orderField) {
      contacts {
        id
        name
        secondName
        email
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_COHORT = gql`
  query cohort($cohortId: String!) {
    cohort(cohort: $cohortId) {
      id
      options {
        contactsLimited
      }
    }
  }
`;

export default function useContactsList({ cohortId, order = 1, orderField = 'name' }) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const isOutdated = useSelector(getIsContactsListIsOutdated);
  const selectedCohort = useSelector(getSelectedCohort);

  useEffect(() => {
    const variables = {
      cohortId: cohortId || selectedCohort.id,
      order,
      orderField,
    };

    dispatch(setContactsIsFetching(true));

    const getContacts = async () => {
      try {
        const {
          data: {
            contacts: { contacts },
          },
        } = await client.query({
          query: GET_CONTACTS,
          variables,
          fetchPolicy: 'no-cache',
        });
        dispatch(setContactsList(contacts));
        dispatch(setContactsIsFetching(false));
      } catch (err) {
        dispatch(
          setContactsError(true, 'An error occurred while fetching the contacts.')
        );
        Sentry.captureException(err);
      }
    };

    getContacts();
  }, [isOutdated, order, orderField]);
}

export const useCohortContacts = () => {
  const getCohort = ({ cohort }) => {
    const contactsResult = withSessionHandler(
      useQuery(GET_CONTACTS, {
        variables: {
          cohortId: cohort,
          order: 1,
          orderField: 'name',
        },
        skip: !cohort,
      })
    );

    const cohortResult = withSessionHandler(
      useQuery(GET_COHORT, {
        variables: {
          cohortId: cohort,
        },
        skip: !cohort,
      })
    );

    return {
      loading: contactsResult.loading || cohortResult.loading,
      result: {
        contacts: contactsResult.data?.contacts?.contacts,
        contactsLimited: cohortResult.data?.cohort.options.contactsLimited,
      },
    };
  };

  return {
    getCohort,
  };
};
