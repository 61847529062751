import { gql } from '@apollo/client';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useLazyQuery } from '@apollo/react-hooks';
import bson from 'bson';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setSurveyCohort,
  removeSurveyCohort,
  addCohortCode,
  setUser,
} from 'lib/redux/actions';
import { useCohortCode } from './useCohortCode';

export const FETCH_COHORT_USER = gql`
  query fetchCohortAndUser($cohort: String!, $email: String!) {
    fetchCohortAndUser(cohort: $cohort, email: $email) {
      cohort {
        id
        options {
          immediate_flow
          individual_purchase
          price
          demographicQuestions
          contactsLimited
          includes_retake
          editWindow
          expires_at
        }
      }
      user {
        id
        email
        licenses
      }
    }
  }
`;

const useCohortForSurvey = () => {
  const [loading, setLoading] = useState(true);
  const code = useCohortCode(false);
  const dispatch = useDispatch();
  const [getCohort, { data, error }] = useLazyQuery(FETCH_COHORT_USER);

  let queryCohortId = code ? code.split('-')[0] : '';
  const objectId = bson.ObjectID;
  const queryByName = code && !objectId.isValid(queryCohortId);
  const { user } = useUser();

  let graphQLErrors = null;

  if (queryByName) {
    queryCohortId = code;
  }

  useEffect(() => {
    if (!code) {
      dispatch(addCohortCode(''));
      dispatch(removeSurveyCohort());
    }
  }, [code]);

  useEffect(() => {
    const fn = async () => {
      const result = await getCohort({
        variables: {
          cohort: queryCohortId,
          email: user.email,
        },
        fetchPolicy: 'no-cache',
      });

      if (result.data) {
        if (result.data.fetchCohortAndUser.cohort) {
          dispatch(setSurveyCohort(result.data.fetchCohortAndUser.cohort));
        }

        if (result.data.fetchCohortAndUser.user) {
          dispatch(setUser(result.data.fetchCohortAndUser.user));
        }
      }

      setLoading(false);
    };
    if (code) fn();
  }, [code]);

  if (error) {
    graphQLErrors = error.graphQLErrors;
  }

  return [loading, data, error, graphQLErrors];
};

export default useCohortForSurvey;
