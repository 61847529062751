import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import PreviewImage from './PreviewImage';
import Video from './Video';

function ModalVideo({ videoUrl, previewImage }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <PreviewImage onPreviewClick={toggle} previewImage={previewImage} />
      <Modal centered size="lg" isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Video videoUrl={videoUrl} />
        </ModalBody>
      </Modal>
    </>
  );
}

ModalVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  previewImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
};

export default ModalVideo;
