import React from 'react';
import { Col } from 'reactstrap';

export default function MiddleSectionItem({ itemImage, itemText }) {
  return (
    <Col className="d-flex justify-content-center align-items-center col-12 col-md-4 py-lg-5 pb-5">
      <img className="middle_image" src={itemImage.src} alt="item" />
      {itemText}
    </Col>
  );
}
