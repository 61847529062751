import React from 'react';
import PropTypes from 'prop-types';

export default function PreviewImage({ onPreviewClick, previewImage }) {
  return (
    <>
      <img className="preview_image" src={previewImage.src} alt="Preview" />
      <div
        onClick={onPreviewClick}
        role="button"
        tabIndex={0}
        onKeyPress={onPreviewClick}
      >
        <img
          className="preview_image_play"
          src="/LND-website-play-button.svg"
          alt="Preview"
        />
      </div>
    </>
  );
}

PreviewImage.propTypes = {
  onPreviewClick: PropTypes.func.isRequired,
  previewImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
};
